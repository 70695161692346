import React, { useEffect, useState } from "react";
import { Link} from "react-router-dom";
import { ReactComponent as HomeIcon } from "../../assets/Icons/home.svg";
import { ReactComponent as MyList } from "../../assets/Icons/mylist.svg";
import { ReactComponent as LiveIcon } from "../../assets/Icons/liveIcon.svg";
import { useLocation} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

const MenuBarMin = () =>{
    const location = useLocation();

    const user = useSelector((state) => state?.user?.value);
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
    return(
        <>
        {location?.pathname !== "/login"?(
            <div className="menubarMinContainer" >
                    <Link to="/home">
                        <div className="menuIconMin">
                            <HomeIcon/>
                        </div>
                    </Link>
                    {user ?(
                    <Link to="/my-list">
                          <div className="menuIconMin" >
                              <MyList/>
                          </div>
                      </Link>
                    ) : null}
                   
                    {projectInfo?.projectConfig?.config?.MULTI_CHANNELS_REQUIRED === "true"  ? (<Link to="/live-channels"state={{isLive:true}} >
                        <div className="menuIconMin"><LiveIcon/></div>
                    </Link>):(projectInfo?.projectConfig?.config?.LIVE_REQUIRED==="true" && <Link to="/liveplayer" state={{isLive:true,prevLocation:location?.search?location?.pathname+location?.search:location?.pathname}}>
                        <div className="menuIconMin"><LiveIcon/></div>
                    </Link>)}
            </div>):null
         }
         </>
    )
}
export default MenuBarMin;