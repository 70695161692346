import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Loading from '../../Components/Loading/Loading';
import Categories from './Components/Categories';
import Featured from './Components/Featured';
import Live from './Components/Live';
import * as service from "./service";
const Homepage = () => {
  const projectInfo=useSelector((state)=>state?.projectInfo?.value);
  const accessToken=useSelector((state)=>state?.accessToken?.value);
  const user=useSelector((state)=>state?.user?.value);
  const [loading ,setLoading] = useState(true)
  const [homeData,setHomeData] = useState([])
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");

  const appInfo={
    projectDetails:projectInfo,
    accessToken:accessToken
  }

  useEffect(() => {
    if (
      projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT"
    ) {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]); 

  useEffect(()=>{
    window.scroll(0,0)
   setLoading(true)
     fetchShowDetails();

  },[user])
  const fetchShowDetails = async()=>{
    try{
      const response = await  service.getShowsByCategory(appInfo);
      if(response?.status === 200){
        setHomeData(response?.data?.data)
        setLoading(false)
      }
    }
    catch{
      setLoading(true)
    }
  }

if(loading){
  return(
    <Loading/>
  )
}
  return (
    <div className='homePage'>
      
        {
          homeData?.map((item,index)=>{
           
            if(item?.type==="LIVE") {
              return <Live key={index} data={item}/>
            } else if(item?.type==="FEATURED") {
              return <Featured key={index} data={item} />
            }else{
              return <Categories key={index} data={item} thumbnailOrientation={thumbnailOrientation}  type={item?.type} />
            }
})
        }
    </div>
  )
}

export default Homepage