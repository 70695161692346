import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/Icons/search.svg";
import { ReactComponent as CloseIcon } from "../../assets/Icons/closeFilled.svg";
import { ReactComponent as MenuIcon } from "../../assets/Icons/MenuIcon.svg";
import { ReactComponent as UserAvatar } from "../../assets/Icons/user_avatar.svg";
import { ReactComponent as AboutUs } from "../../assets/Icons/aboutus.svg";
import { ReactComponent as CircleArrow } from "../../assets/Icons/filledCircleArrow.svg";
import { ReactComponent as SignOutIcon } from "../../assets/Icons/signOut.svg";
import { ReactComponent as Login } from "../../assets/Icons/login.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import { getAccessToken } from "../../Redux/AToken/ATokenSlice";

import {
  appInfo,
  authenticate2,
  getUserSubscription,
} from "../../network/service";
import * as service from "./service";
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import {ReactComponent as PrivacyPolicy} from "../../assets/Icons/privacypolicy.svg";
import {ReactComponent as TermsofUse} from "../../assets/Icons/terms.svg";
import {ReactComponent as TvLinkApp} from "../../assets/Icons/tvLinkApp.svg";
import {ReactComponent as Contact} from "../../assets/Icons/contactSupport.svg";
import { ReactComponent as AccountSettings } from "../../assets/Icons/accountSettings.svg";
import { imageUrlCheck } from "../../utils/utils";
import { getMenuBarData } from "../../Redux/MenuBar/MenuBarSlice";
import HeaderCategories from "./HeaderCategories";
import { getMoreInfo } from "../../Redux/MoreInfo/moreInfoSlice";
import { getPodcastModal } from "../../Redux/PodcastModal/podcastModalSlice";
import { getEventMoreInfo } from "../../Redux/MoreInfo/eventMoreInfoSlice";
import { getMoreInfoOther } from "../../Redux/MoreInfo/moreInfoOtherSlice";

const Header = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userDetails = useSelector((state) => state?.userDetails?.value);
  const menuBar = useSelector((state) => state?.menuBar?.value);

  const [inputValue, setInputValue] = useState("");
  const [searchResults, setSearchResults] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [menuHandler, setMenuHandler] = useState(false);
  const [browseHover, setBrowseHover] = useState("");
  const [browseMoreMediumDevice, setBrowseMoreMediumDevice] = useState(false);
  const [position, setPosition] = useState(window.pageYOffset);
  const [headerVisible, setHeaderVisible] = useState(true);
  const [userInfo, setUserInfo] = useState(false);
  const [previousLocation, setPreviousLocation] = useState("");
  const [IsSearchLoading, setIsSearchLoading] = useState(false);
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [menuStatus , setMenuStatus] = useState(true);
  
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const menuRef = useRef(null);
  const searchRefContainer = useRef(null);
  const searchRefSmall = useRef(null);
  const searchRefSmallContainer = useRef(null);
  const searchSuggestionRef = useRef(null);
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };  
 

  useEffect(() => {
    const handleScroll = () => {
      let moving = window.pageYOffset;
      // setHeaderVisible(position > moving);
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  useEffect(() => {
    if (searchActive) {
      searchRef?.current.focus();
      searchRefSmall?.current?.focus();
      searchRefSmallContainer?.current?.focus();
      setPreviousLocation(location);
    }

  }, [searchActive]);

  useEffect(() => {
    if (accessToken) {
      fetchUserSubscriptionDetails();
    }
  }, [user, accessToken]);



  useEffect(() => {
    if (
      projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT"
    ) {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);

  
   useEffect(() => {
    let handleOutClick = (e) => {
      if (
        !searchRefContainer?.current?.contains(e.target) &&
        !searchRefSmallContainer?.current?.contains(e.target)
      ) {
        setInputValue("");
        setSearchActive(false); //outside click
      }

      if (!searchSuggestionRef?.current?.contains(e.target)) {
        setInputValue("");
        // setSearchActive(false)
        setSearchResults(null);
      }

      if (!menuRef?.current?.contains(e.target)) {
        setMenuHandler(false); //outside click
      }
    };

    window.addEventListener("click", handleOutClick);
    return () => {
      window.addEventListener("click", handleOutClick);
    };
  }, [
    searchRefContainer,
    searchRefSmallContainer,
    menuRef,
    searchSuggestionRef,
  ]);


  useEffect(() => {
    if (inputValue) {
      setIsSearchLoading(true);
      fetchSearchData();
    } else {
      setIsSearchLoading(false);
      setSearchResults(null);
    }
  }, [inputValue]);


  const fetchUserSubscriptionDetails = async () => {
    const userSubResponse = await getUserSubscription(appInfo);
    if (userSubResponse?.status === 200) {
      dispatch(
        getUserSubscriptionData({
          userSubscription: userSubResponse?.data,
        })
      );
    }
  };
  const fetchSearchData = async () => {
    try {
      const response = await service?.getSearchData(appInfo, inputValue);
      if (response?.status === 200) {
        setSearchResults(response?.data?.data);
        setIsSearchLoading(false)
      } else {
      }
    } catch (err) {
      setIsSearchLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

    //Search functionality  start
    const searchSubmit = (value) => {
      if (value) {
        setInputValue("");
        setSearchActive(false);
        if (searchResults?.length > 0) {
          navigate(`/search?query=${value}`, { state: { searchResults } });
        } else {
          navigate(`/search?query=${value}`);
        }
      } else {
        navigate(previousLocation?.pathname, { state: previousLocation?.state });
        setInputValue("");
      }
    };
  const searchSubmitBrowseMore = (value) => {
    setSearchActive(true)
    if (value) {
      setInputValue("");
      navigate(`/search?query=${value}`);
      setBrowseMoreMediumDevice(null);
    } else {
      navigate(previousLocation?.pathname, { state: previousLocation?.state });
      setInputValue("");
    }
    if (!searchActive) {
      setSearchActive(true);
    } else {
      setSearchActive(false);
      setBrowseMoreMediumDevice(null);
    }
  };

/// end

  const logoutHandler = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("selectedSubId")
    localStorage.removeItem("deviceType")
    localStorage.removeItem("selectedAmount")
    localStorage.removeItem("vanityUrl")
    tokenAuthenticate();
    // window.FB.logout(function(response) {
    //   // user is now logged out
    // });
  };

  const tokenAuthenticate = async () => {
    const response = await authenticate2(appInfo);
    if (response?.status === 200) {
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
      navigate("/home");
      setTimeout(() => {
        dispatch(
          getUser({
            user: null,
          })
        );
      }, 300);
    }
  };
  const showClickHandler = (data, medium) => {
    if (medium) {
      setBrowseMoreMediumDevice(false);
    }
    if (data?.type === "LIVE_EVENT") {
        navigate(`/event/${data?.event_id}`, { state: {  showId:data?.show_id,eventId:data?.event_id } })
    } else if (data?.type === "PODCAST") {
      let podcastData = {
        isOpen: true,
        data
      }
      dispatch(
        getPodcastModal({
          podcastModal: podcastData
        })
      )
    } else if (data?.type === "NEWS") {
      navigate(`/news-info/${data?.show_id}`)
    }
    else if(data?.type === "CONTINUE_WATCHING"){
      navigate(`/show-details/${data?.vanity_url}`, {
        state: {  videoDetails:data ,showId: data?.show_id, type: data?.type },
      });
    }
    else if (data?.type === "UPCOMING_EVENT") {
        navigate(`/event/${data?.vanity_url?data?.vanity_url:data?.event_id}`, { state: {eventId:data?.event_id } })
    }
    else if(data?.type === "VIDEO"){
      navigate(`/show-details/${data?.vanity_url}`, {
        state: { videoDetails:data },
      })
    }
    else if(data?.type === "SHOW"){
      navigate(`/show-details/${data?.vanity_url}`, {
        state: { showId: data?.show_id,type:data?.type },
      })
    }
    else if(data?.type === "FASTCHANNEL"){
      navigate(`/live-channels`, { 
      })
    }
    else if(data?.type === "EVENT"){
      navigate(`/event/${data?.vanity_url?data?.vanity_url:data?.event_id}`,{state:{ eventId:data?.event_id }})
    }
    else if( data?.type === "Episode"){
      navigate(`/show-details/${data?.vanity_url}`, {
        state: { videoDetails:data },
      })
    }
    else if(data?.type!=='episodes'){
      navigate(`/show-details/${data?.vanity_url}`, {
        state: { showId: data?.show_id,type:data?.type },
      })
    }
    else if(data?.type === "PODCASTS"){
      let podcastData = {
        isOpen:true,
        data
      }
      dispatch(
        getPodcastModal({
          podcastModal:podcastData
        })
      )
    }
    else {
        navigate(`/show-details/${data?.vanity_url}`, { state: { showId: data?.show_id } })
        dispatch(
          getMoreInfo({
            moreInfo: true
          })
        )
      
    }
    
    setSearchResults(null);
  };

  const handleClick = () => {
    setMenuStatus (!menuStatus);
    dispatch(
      getMenuBarData({
        // menuBar:!menuStatus,
        menuBar:!menuBar,
      })
    );
  }
  return (
    <div className="header">
      <div className="headerContainer" >
        <div className="wrapper">
          <div className="leftContainer">
            <div className="menuIconContainer" >
              <MenuIcon
                  ref={menuRef}
                  onClick={()=>handleClick()}
                />
            </div>
            <div className="logoContainer">
              <Link to={"/home"}>
              <h1>
                  <img
                    src={projectInfo?.projectConfig?.config?.LOGO}
                    alt="Logo"
                  />
                </h1>
              </Link>  
            </div>   
          </div>

          <div className="centerContainer">
            <div className="inputSearchContainer"
              ref={searchRefContainer}
              onClick={() => {
              setSearchActive(true);
              searchRef?.current.focus();
             }}> 
              <input
                  ref={searchRef}
                  onClick={() => setSearchActive(true)}
                  type="text"
                  value={inputValue || ""}
                  placeholder="Search"
                  onChange={(e) => {
                    setInputValue(e?.target?.value);
                  }}
                  onKeyDown={(e) =>
                    e.key === "Enter" ? searchSubmit(inputValue) : ""
                }
              />
              <button  onClick={ () => {
                      searchSubmit(inputValue);
                      searchRef?.current.focus(); }}>
                    < SearchIcon/>
              </button>
          </div>        
        </div>
       <div className="rightContainer">
            <div className="userIconContainer" >
              <UserAvatar 
                onClick={() => setMenuHandler(true)}
                ref={menuRef}
              />
            </div>      
       </div>   
    </div>    
  </div>
 

<div className={browseMoreMediumDevice ? "active menuBarOverlay" : "menuBarOverlay"}
    onClick={() => setBrowseMoreMediumDevice(false)}>
</div>
<div className={searchActive ? "searchDropContainer " : " hide searchDropContainer"}
    onClick={() => {setSearchActive(!searchActive);}}>
</div>
<div className={menuHandler?"rightUserContainer Active":"rightUserContainer "}></div>


<div className="browseMoreResponsive">
  {!IsSearchLoading && searchResults?.length > 0 && inputValue && (
  <div className="imageListContainer" ref={searchSuggestionRef}>
    <ul className="listContainer">
      {searchResults?.slice(0, 8)?.map((item, index) => (
        <div className="categorySection" key={index}>
          <h6 className="heading">{item?.category_name}</h6>
          {item?.shows?.map((show, showIndex) => (
            <li className="lists" key={`${index}-${showIndex}`}>
              <Link to={`/show-details/${show.vanity_url}`}>
                <div className="list" onClick={() => showClickHandler(show, true)}>
                  <div className="left">
                    <div className="imageContainer">
                      {imageUrlCheck(thumbnailOrientation === "PORTRAIT" ? show?.logo : show?.logo_thumb) ? (
                        <img
                          src={thumbnailOrientation === "PORTRAIT" ? show?.logo : show?.logo_thumb}
                          alt="Thumb"
                        />
                      ) : (
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}${thumbnailOrientation === "PORTRAIT" ? show?.logo : show?.logo_thumb}`}
                          alt="Thumb"
                        />
                      )}
                    </div>
                  </div>
                  <div className="right">
                    <h1 className="title">{show?.show_name}</h1>
                    {/* <span className="director">{show?.director}</span> */}
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </div>
      ))}
    </ul>
    <div className="seeAll" onClick={() => searchSubmit(inputValue)}>
                  <button>
                    SEE ALL
                  </button>
                </div>
  </div>
)}

    
    {menuHandler?( <div className="menuListContainer ">
             <div className="userContainerList">    
                     <Link to="/privacy-policy">
                        <div className="userRow">
                          <PrivacyPolicy/>
                          <li>Privacy Policy</li>
                        </div>   
                     </Link>
                    <Link to="/terms-and-conditions">
                      <div className="userRow">
                        <TermsofUse/>
                        <li>Terms of Use</li>
                      </div>
                     </Link>
                    <Link to="/about-us">
                      <div className="userRow">
                        <AboutUs/>
                        <li>About Us</li>
                      </div>  
                     </Link>  
                     <Link to="/tv">
                      <div className="userRow">
                        <TvLinkApp/>
                        <li>Link TV App</li>
                      </div>
                     </Link> 
                    <Link to="/contact-support">
                      <div className="userRow">
                        <Contact/>
                        <li>Contact Support</li>
                      </div>   
                     </Link>
                     {user ? (
                      <div className="userRow" onClick={() => navigate("/settings")}>
                            <AccountSettings/>
                            <li>Account Settings</li>
                      </div>
                      ):(null)
                     } 
                     {user ? (
                      <div className="userRow" onClick={() => logoutHandler()}>
                            <SignOutIcon/>
                            <li>Log Out</li>
                      </div>
                      ):(
                        <div className="userRow" onClick={() => navigate("/login")}>
                  
                            <Login />
                            <li>Login</li>
                      </div>
                       )
                     } 
                        
        </div>
      </div>):null}
      
  </div>
</div>
  );
};

export default Header;