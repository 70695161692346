import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from "react-router-dom";

const PublicRoutes = () => {
  const user = useSelector((state) => state?.user?.value);
  const location = useLocation()
    if(!user){
        return(
            <Outlet/>
        )
    }else{
        if(location?.state?.tv){
        return <Navigate to={location?.state?.location?.pathname + location?.state?.location?.search} />
        }else {
            if(location?.state?.isFrom)
             { return  <Navigate  to={location?.state?.path ? location.state.path : "/home"} state={location.state} /> }
            else{
             return <Navigate to={location?.state?.path?location?.state?.path:"/home"} state={location.state} />
            }  
            
        }
    }
 
}

export default PublicRoutes
