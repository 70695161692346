import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play.svg";
import { appInfo, fetchEventDetails } from "../../network/service";
import { updateWatchlistData } from "../../network/service";
import { ToastContainer, toast } from "react-toastify";
import { fetchWatchlistShows } from "../../Screens/MyList/service";
import { getMyListData } from "../../Redux/MyList/MyListSlice";
import { ReactComponent as PremiumIcon } from "../../assets/Icons/premium.svg";
import { imageUrlCheck } from "../../utils/utils";
import { getPodcastModal } from "../../Redux/PodcastModal/podcastModalSlice";
import Avatar from '@mui/material/Avatar';
import { getEventMoreInfo } from "../../Redux/MoreInfo/eventMoreInfoSlice";
import { getMoreInfoOther } from "../../Redux/MoreInfo/moreInfoOtherSlice";
import { getMoreInfo } from "../../Redux/MoreInfo/moreInfoSlice";

const ShowCard = ({ data, metaData, imageUrl, type,modalPageNavigation, showName }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userSubscriptionData = useSelector(
    (state) => state?.userSubscription?.value
  );
  const [watchlistStatus, setWatchlistStatus] = useState();
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [imagURL,setImageURL] = useState(projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  useEffect(() => {
    if (data?.watchlist_flag === 1) {
      setWatchlistStatus("added");
    } else {
      setWatchlistStatus("removed");
    }
  }, [data]);

  useEffect(() => {
    if (
      projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT"
    ) {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);

  const fetchWatchlist = async () => {
    const response = await fetchWatchlistShows(appInfo);
    if (response?.status === 200) {
      dispatch(
        getMyListData({
          myList: response?.data?.data,
        })
      );
    }
  };

  const updateWatchlist = async (showId, flag) => {
    try {
      const response = await updateWatchlistData(appInfo, showId, flag);

      if (response?.status === 200) {
        fetchWatchlist();
        if (flag === 1) {
          // toast.success("Added to mylist", {
          //   position: "bottom-center",
          // });
          setWatchlistStatus("added");
        } else {
          // toast.success("Removed from mylist", {
          //   position: "bottom-center",
          // });
          setWatchlistStatus("removed");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addToWatchlist = (showId) => {
    if (user) {
      updateWatchlist(showId, 1);
    } else {
      navigate("/login");
    }
  };

  const removeFromWatchlist = (showId) => {
    updateWatchlist(showId, 0);
  };

 



  const showHandleClick = (id, showId) => {
   
    // document.body.style.overflow='hidden'
    // document.body.className="scrollLock"
    
    if(data?.type==="LIVE_EVENTS"||data?.type==="LIVE_EVENT" ){
      if(user){
        linearEventHandler()
      }else{
      navigate("/login");
      }
    }
     else if (data?.type === "PODCAST") {
      let podcastData = {
        isOpen: true,
        data
      }
      dispatch(
        getPodcastModal({
          podcastModal: podcastData
        })
      )
    } else if (data?.type === "NEWS") {
      navigate(`/news-info/${id}`)
    }
    else if(data?.type === "CONTINUE_WATCHING"){
      navigate(`/show-details/${data?.vanity_url}`, {
        state: {  videoDetails:data ,showId: data?.show_id, type: data?.type },
      });
    }
    else if (data?.type === "UPCOMING_EVENT") {
      if (modalPageNavigation === false) {
        dispatch(
          getEventMoreInfo({
            eventMoreInfo: {
              isModal: true,
              eventId: data?.vanity_url ? data?.vanity_url : data?.event_id
            }
          })
        )
      } else {
        navigate(`/event/${id}`, { state: { showId,eventId:data?.event_id } })

      }
    }
    else if(data?.type === "VIDEO"){
      navigate(`/show-details/${data?.vanity_url}`, {
        state: { videoDetails:data },
      })
    }
    else if(data?.type === "SHOW"){
      navigate(`/show-details/${data?.vanity_url}`, {
        state:{showId: data?.show_id,type:data?.type ,vanityUrl:data?.vanity_url}
      })
    }
    else if(data?.type === "FASTCHANNEL"){
      navigate(`/live-channels`, { 
      })
    }
    else if(data?.type === "EVENT"){
      navigate(`/event/${data?.vanity_url?data?.vanity_url:data?.event_id}`,{state:{ showId,eventId:data?.event_id }})
    }
    else if( type === "episodes"){
      navigate(`/show-details/${showName}`, {
        state: { videoDetails:data },
      })
    }
    else if(type!=='episodes'){
      navigate(`/show-details/${data?.vanity_url}`, {
        state: { showId: data?.show_id,type:data?.type },
      })
    }
    else if(data?.type === "PODCASTS"){
      let podcastData = {
        isOpen:true,
        data
      }
      dispatch(
        getPodcastModal({
          podcastModal:podcastData
        })
      )
    }
    else {

      if (modalPageNavigation === false) {
        dispatch(
          getMoreInfoOther({
            moreInfoOther: {
              isModal: true,
              showId: id
            }
          })
        )
      } else {
        navigate(`/show-details/${id}`, { state: { showId } })
        dispatch(
          getMoreInfo({
            moreInfo: true
          })
        )
      }
    }
  }

  const subscriptionCheck = (userSub, videoSub) => {
    const hasMatch = userSub?.some((user) =>
      videoSub?.some((video) => user.sub_id === video.subscription_id)
    );
    return hasMatch;
  };


  const getEventDetails = async () => {

    const response = await fetchEventDetails(appInfo, data?.event_id);
    if (response?.status === 200) {
      return response?.data?.data;
    }
  };

  const linearEventHandler = async () => {
    let linearEventDetails = await getEventDetails()
    if(linearEventDetails?.free_event === true){
      navigate("/liveplayer", {state:{eventId:linearEventDetails?.event_id}})
    }else if(
      linearEventDetails?.payper_flag ===1 || 
      linearEventDetails?.premium_flag ===1 || 
      linearEventDetails?.rental_flag ===1)
      {
        let isSubscribedUser = userSubscriptionData?.data?.length > 0 ? subscriptionCheck(userSubscriptionData?.data,linearEventDetails?.subscriptions) :false;
        if(isSubscribedUser){
            navigate("/liveplayer", {state:{eventId:linearEventDetails?.event_id}})
        }else{
          localStorage.setItem("eventId",linearEventDetails?.event_id)
          navigate("/subscription" , {state:{eventId:linearEventDetails?.event_id}})
        }
      }

  }

  return (
    <div
      className={
        type === "UPCOMING_EVENTS"
          ? "showCardContainer"
          : "showCardContainer showContents"
      }
      title={data?.show_name || data?.video_title}
      // onClick={showHandleClick}
      onClick={() => showHandleClick(data?.vanity_url ? data?.vanity_url : data?.show_id ? data?.show_id : data?.event_id, data?.show_id)}
    >
      <div className="premiumStatus">
        
         { data?.is_free_video === true ? (
          <div className="free">
            <img
              src={require("../../assets/Images/free_icon.png")}
              alt="FreeIcon"
            />
          </div>
        )
         : data?.is_locked === true ?(
          <div className="lock">
            <img
            src={require("../../assets/Images/lock.png")}
            alt="LockIcon"
            />
          </div>
        )
        :null
        }
      </div>

      <div className="videoCard">
        {/* <div className="wrapper"> */}
        <div className="imageContainer">
          {imageUrlCheck(
            thumbnailOrientation === "PORTRAIT"
              ? data?.logo || data?.thumbnail
              : data?.logo_thumb || data?.thumbnail_350_200
          ) === false ? (
            <img
              src={
                thumbnailOrientation === "PORTRAIT"
                  ? `${process.env.REACT_APP_IMAGE_URL}${
                      data?.logo || data?.thumbnail
                    }`
                  : `${process.env.REACT_APP_IMAGE_URL}${
                      data?.logo_thumb || data?.thumbnail_350_200
                    }`
              }
              alt="ShowImage"
            />
          ) : (
            <img
              src={
                thumbnailOrientation === "PORTRAIT"
                  ? `${data?.logo || data?.thumbnail}`
                  : `${data?.logo_thumb || data?.thumbnail_350_200}`
              }
              alt="ShowImage"
            />
          )}
          {type === "CONTINUE_WATCHING" && (
            <div className="continueWatchingBar">
              <div
                className="line"
                style={{
                  width:
                    data?.watched_percentage <= 1
                      ? 1 + "%"
                      : data?.watched_percentage + "%",
                }}
              ></div>
            </div>
          )}
        </div>
        <div className="videoCard__info">
          <Avatar
            className="videoCard__avatar"
            alt=""
            src={
              imageUrlCheck(
                thumbnailOrientation === "PORTRAIT"
                  ? data?.logo || data?.thumbnail
                  : data?.logo_thumb || data?.thumbnail_350_200
              ) === false
                ? thumbnailOrientation === "PORTRAIT"
                  ? `${process.env.REACT_APP_IMAGE_URL}${
                      data?.logo || data?.thumbnail
                    }`
                  : `${process.env.REACT_APP_IMAGE_URL}${
                      data?.logo_thumb || data?.thumbnail_350_200
                    }`
                : thumbnailOrientation === "PORTRAIT"
                ? `${data?.logo || data?.thumbnail}`
                : `${data?.logo_thumb || data?.thumbnail_350_200}`
            }
          />
          <div className="videoCard__text">
            {data?.show_name&&
            <h4>{data?.show_name}</h4>
            }
            
            <p>
              {data?.duration_text}{data?.year &&data?.duration_text&&" • "} {data?.year}
            </p>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default ShowCard;
