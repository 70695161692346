import { fetchApiData } from "../../utils/utils";

export const getNews= async (appInfo) => {
    const api = `news/list`;
    return fetchApiData(appInfo,api)
}

export const getNewsDetails= async (appInfo,newsId) => {
    return new Promise((res,rej)=>{
         const api = `news/${newsId}`;
        res(fetchApiData(appInfo,api))
        // const api = `news/${newsId}`;
        // return fetchApiData(appInfo,api)
    })
}
