import React, { useEffect, useState ,useRef }  from "react";
import { Link} from "react-router-dom";
import { ReactComponent as HomeIcon } from "../../assets/Icons/home.svg";
import { ReactComponent as MyList } from "../../assets/Icons/mylist.svg";
import { ReactComponent as LiveIcon} from "../../assets/Icons/liveIcon.svg";
import { useLocation} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

  import { getMenuBarData } from "../../Redux/MenuBar/MenuBarSlice";
  const Menubar =()=>{

    const location = useLocation();
    const dispatch = useDispatch();
    const menuRef = useRef(null);

    const user = useSelector((state) => state?.user?.value);
    const menuBar = useSelector((state) => state?.menuBar?.value);
    const [menuHandler, setMenuHandler] = useState(true);
    
    const projectInfo = useSelector((state) => state?.projectInfo?.value);

    useEffect(() => {

      let handleOutClick = (e) => {
     
        if (!menuRef?.current?.contains(e.target)
        &&(menuBar)
        ) {
          setMenuHandler(false)//outside click
          dispatch(
            getMenuBarData({
             
                menuBar:!menuHandler,
            })
          );  
        }
      };
      window.addEventListener("click", handleOutClick);
      return () => {
        window.addEventListener("click", handleOutClick);
      };
    }, [
      menuRef,menuBar
    ]);

    const menuBarHandle =() =>{
      setMenuHandler(false);
      dispatch(
        getMenuBarData({
          menuBar:!menuBar,
         
        })
      )
    }

 

    return(
        <>
        {location?.pathname !== "/login"?(
         
          <div className= "menuBodyContainer"
             ref={menuRef}
            >
            <div className={menuHandler? "menubarContainer Active ": "menubarContainer"}> 
            <div className="overlay" ref={menuRef} onClick={() =>menuBarHandle()}></div>
            {menuHandler?(
              <div className="menuMaxContainer" >
                <Link to="/home" onClick={() => menuBarHandle()}>
                    <div className="menuIcon">
                        <HomeIcon/>
                        <li>Home</li>
                    </div>
                </Link>
                {user ?(
                <>
                   <Link to="/my-list">
                        <div className="menuIcon"  onClick={() => menuBarHandle()}>
                            <MyList/>
                            <li>MyList</li>
                        </div>
                    </Link>
                 </> )
                :null}         
              
                {projectInfo?.projectConfig?.config?.MULTI_CHANNELS_REQUIRED === "true"  ?
                  (<Link to="/live-channels"state={{isLive:true}}  onClick={() => menuBarHandle()}>
                      <div className="menuIcon"><LiveIcon/> <li>Live</li></div>
                    </Link>):
                  (projectInfo?.projectConfig?.config?.LIVE_REQUIRED==="true" &&
                    <Link to="/liveplayer" state={{isLive:true,prevLocation:location?.search?location?.pathname+location?.search:location?.pathname}}  onClick={() => menuBarHandle()}>
                      <div className="menuIcon"><LiveIcon/> <li>Live</li></div>
                    </Link>)}
                </div>
            ):null}
            </div>
           </div>
         ):null}   
        </>
    )
}
export default Menubar;