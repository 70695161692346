import React, { useEffect, useState } from "react";
import { ReactComponent as RightArrow } from "../../../assets/Icons/rightArrow.svg";
import ShowCard from "../../../Components/ShowCard/ShowCard";
import MagazineCard from "../../../Components/MagazineCard/MagazineCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { rowItemCount } from "../../../utils/utils";
import { useWindowWidth } from "@react-hook/window-size";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const Categories = ({ data,thumbnailOrientation }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const websiteRowCount= projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT
  const navigate = useNavigate();
  const [rowItemsClass,setRowItemsClass] = useState("")
  const [sliceCount,setSliceCount] = useState()
  const [isShowMore,setIsShowMore] = useState(false)

  const windowSize = useWindowWidth()

  useEffect(() => {
    if(windowSize>980){
      if(websiteRowCount){
        setSliceCount(Number(websiteRowCount))
        setRowItemsClass(rowItemCount(websiteRowCount))
      }else{
        setRowItemsClass("rowCount4")
      }
    }else{
      setRowItemsClass("")
    }
  }, [projectInfo,windowSize]);

const showMoreHandler = () =>{
  setSliceCount(sliceCount+Number(websiteRowCount))
  setIsShowMore(true)
}
const showLessHandler = () =>{
  setSliceCount(Number(websiteRowCount))
  setIsShowMore(false)
}
  return (
    <div className="categoriesContainer">
      <div className="wrapper">
      
        <div
          className="categoryNameContainer"
       
          onClick={() =>
            navigate(`/category/${data?.key}`, {
              state: {careers:data?.key,type:data?.type},
            })
          }
        >

          <h1>{data?.category_name}</h1>
        </div>
        <div className="itemsContainer">
        {data?.shows?.slice(0, sliceCount).map((item, index) => (
          data?.type !== "MAGAZINES" ? (
            <div key={index} className={thumbnailOrientation === "PORTRAIT" ? `items portrait ${rowItemsClass}` : `items landscape ${rowItemsClass}`}>
              <ShowCard
                data={item}
                imageUrl={true}
                season={false}
                metaData={true}
                modalNavigation={true}
              />
            </div>
          ) : (
            <div key={index} className={thumbnailOrientation === "PORTRAIT" ? `magazine portrait ${rowItemsClass}` : `magazine landscape ${rowItemsClass}`}>
              <MagazineCard
                data={item}
                url={data.url}
                metaData={true}
                cardHover={true}
              />
            </div>
          )
        ))}



            {
              data?.shows?.length>sliceCount&&(
                <div className="showMoreContainer" title="Show More" onClick={showMoreHandler}>
                  <ExpandMoreIcon sx={{color:"#fff"}}/>
                </div>
              )
            }
            {
             isShowMore && sliceCount >= data?.shows?.length&&
            <div className="showLessContainer" title="Show Less" onClick={showLessHandler}>
              <ExpandMoreIcon sx={{color:"#fff",transform:"rotate(-180deg)"}}/>
            </div>
            }
          {/* <Swiper
            modules={[Navigation]}
            spaceBetween={10}
            slidesPerView={6}
            navigation={true}
            watchSlidesProgress
            loop={false}
            className={thumbnailOrientation==="PORTRAIT"?"portrait":"landscape"}
            breakpoints={{
              320: {
                slidesPerView: thumbnailOrientation==="PORTRAIT"?2:1,
                spaceBetween: 5,
              },
              480: {
                slidesPerView: thumbnailOrientation==="PORTRAIT"?3:2,
                spaceBetween: 5,
              },
              768: {
                slidesPerView: thumbnailOrientation==="PORTRAIT"?4:2,
                spaceBetween: 10,
              },
              980: {
                slidesPerView: projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT:4,
                spaceBetween: 10,
              },
            }}
          >
            {data?.shows?.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="show">
                  <ShowCard data={item} season={false} metaData={true} type={data?.type} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper> */}
        </div>
      </div>
    </div>
  );
};

export default Categories;
