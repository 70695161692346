import React from "react";
import Card2 from "../../../Components/ShowCard/Card2";

const RelatedVideosContainer = ({similarVideos}) => {
  return (
    <div className="releated_Video__Container">
      <h1 className="relatedHeading">You May Also Like</h1>
      <div className="recommendedVideos">
        {
           similarVideos?.map((item,index)=>(
            <div className="items" key={index}>
                <Card2 data={item} />
            </div>
           )) 
        }
      </div>
      {/* </div> */}
    </div>
  );
};

export default RelatedVideosContainer;
