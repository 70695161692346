import { configureStore } from "@reduxjs/toolkit";
import UserReducer from "./UserSlice/UserSlice";
import AppInfoReducer from "./AppInfo/AppInfoSlice";
import DeviceIdReducer from "./DeviceId/DeviceIdSlice";
import ATokenReducer from "./AToken/ATokenSlice";
import projectInfoReducer from "./ProjectInfo/ProjectInfoSlice";
import myListReducer from "./MyList/MyListSlice";
import userSubscriptionReducer from "./UserSubscriptionDetails/userSubscriptionSlice";
import deviceSubscriptionReducer from "./DeviceSubscriptionData/deviceSubscriptionSlice";
import UserDetailsReducer from "./UserDetailsSlice/UserDetailsSlice";
import sessionIdReducer from "./SessionId/sessionIdSlice";
import moreInfoReducer from "./MoreInfo/moreInfoSlice";
import EventMoreInfoReducer from "./MoreInfo/eventMoreInfoSlice";
import moreInfoOtherReducer from "./MoreInfo/moreInfoOtherSlice";
import homeDataReducer from "./HomeData/homeDataSlice";
import podcastReducer from "./PodcastModal/podcastModalSlice";
import menuBarReducer from "./MenuBar/MenuBarSlice";
import languageModalReducer from "./LanguageModal/languageModalSlice";
import selectedScheduleReducer from "./SelectedScheduleChannel/selectedScheduleChannelSlice";
import scheduleModalReducer from "./ScheduleModal/scheduleModalSlice";
import liveScheduleReducer from "./LiveScheduleData/liveScheduleDataSlice";

export default configureStore({
    reducer:{
        user:UserReducer,
        userDetails:UserDetailsReducer,
        appInfo:AppInfoReducer,
        deviceId:DeviceIdReducer,
        accessToken:ATokenReducer,
        projectInfo:projectInfoReducer,
        myList:myListReducer,
        userSubscription:userSubscriptionReducer,
        deviceSubscription:deviceSubscriptionReducer,
        sessionId:sessionIdReducer,
        moreInfo:moreInfoReducer,
        homeData:homeDataReducer,
        moreInfoOther:moreInfoOtherReducer,
        podcastModal:podcastReducer,
        menuBar:menuBarReducer,
        eventMoreInfo:EventMoreInfoReducer,
        scheduleModal:scheduleModalReducer,
        liveSchedule:liveScheduleReducer,
        selectedSchedule:selectedScheduleReducer,
        languageModal:languageModalReducer,
    }
})
