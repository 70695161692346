import React, { useEffect, useState } from 'react'
import {ReactComponent as MailIcon} from "../../../assets/Icons/mailIcon.svg"
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { analytics2, authenticate2, emailRequestCheck } from '../../../network/service';
import { useDispatch } from 'react-redux';
import { getAccessToken } from '../../../Redux/AToken/ATokenSlice';
import { getUserDetails } from '../../../Redux/UserDetailsSlice/UserDetailsSlice';
import { getUser } from '../../../Redux/UserSlice/UserSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import Translate from '../../MultiLanguage/Translate';
import { getSessionId } from '../../../utils/utils';
import { getSessionIdValue } from '../../../Redux/SessionId/sessionIdSlice';


const VerifyEmail = ({inputValue,isEmailSent,id,appInfo}) => {
  const [open, setOpen] = React.useState(isEmailSent);
  const [redirectUrl, setRedirectUrl] = useState("/home");
  const [locationState, setLocationState] = useState();
  
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch()

  useEffect(() => {
    window.scroll(0, 0);
    if (location?.state) {
      setLocationState(location?.state);
    }
    if (location?.state?.path) {
      setRedirectUrl(location?.state?.path);
    } else {
      setRedirectUrl("/home");
    }
  }, [location?.state]);
  useEffect(()=>{
    const interval = setInterval(() => {
      emailVerifiedCheck()
    }, 5000);
    return () => clearInterval(interval)
  },[])
  const handleClose = (event,reason) => {
    if (reason && reason == "backdropClick") 
    return;
    setOpen(false);
    setTimeout(() => {
    }, 500);
  };

  const emailVerifiedCheck = async () => {
    const link = redirectUrl
    try{
      const response  = await emailRequestCheck(appInfo,id)
      if(response?.status === 200){
        if(response?.data?.data[0]?.user_id){
          localStorage.setItem("userId", response?.data?.data[0]?.user_id);
          await tokenAuthenticate()
          await updateDeviceAnalytics(
            response?.data?.data[0]?.user_id,
            appInfo,
            response?.data?.data[0]?.user_email,
            response?.data?.data[0]?.first_name
          );
          setTimeout(() => {
            dispatch(
              getUser({
                user: response?.data?.data[0]?.user_id,
              })
            );
          }, 1000);
          const sessionId = await getSessionId(appInfo);
          dispatch(
            getSessionIdValue({
              sessionId: sessionId,
            })
          );
          navigate(link, { state: locationState });
        }
      }
    }catch(err){

    }
  }
  const tokenAuthenticate = async () => {
    const response = await authenticate2(appInfo);
    if (response?.status === 200) {
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
      dispatch(
        getUserDetails({
          userDetails: response?.data,
        })
      );
    }
  };

  const updateDeviceAnalytics = async (userId, appInfo, userEmail, userName) => {
    await analytics2(userId, appInfo, userEmail, userName);
  };

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        transitionDuration={500}
        className='verifyEmailModal'
        maxWidth="xl"
      >
        <DialogContent>
        <div className="top">
            <MailIcon/>
        </div>
        <div className="bottom">
            <div className="heading"><h1><Translate textKey={'email_conformation'}/></h1></div>
            <p><Translate textKey={'if_account_associated'} /> <span>{inputValue}</span> <Translate textKey={'check_email_message'}/></p>
            <br/>
            <p><Translate textKey={'link_expires_soon_message'}/></p>
        </div>
        </DialogContent>
      </Dialog>
  )
}

export default VerifyEmail
