import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import ShowCard from "../ShowCard/ShowCard";
import { ReactComponent as Arrow } from "../../assets/Icons/filledArrow.svg";
import { useSelector } from "react-redux";
import Translate from "../MultiLanguage/Translate";

const ShowsRow = ({ season, data,type, title, metaData,watchVideoHandler,setVideoPlayer,similarVideos,thumbnailOrientation, showName }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  
  const [seasonButton, setSeasonButton] = useState(false);
  const [details, setDetails] = useState();
  const [seasons, setSeasons] = useState([]);
  const [seasonName,setSeasonName] = useState("Season 1")
  useEffect(() => {
    if (data && !season) {
      setDetails(data);
    } else {
        setDetails(data[0]?.videos)
      setSeasons(data);
    }
  }, [data]);
  return (
    <div className="showsRow">
      {title && <h1 className="heading">{title}</h1>}
      {season && (
        <div
          className={seasonButton?"seasonContainer zIndexController":"seasonContainer"}
          onClick={() => setSeasonButton(!seasonButton)}
        >
          <span className="selected">{seasonName}</span>
        <div className={season?.length>5?"listContainer scroll":"listContainer"}>

          <div className={seasonButton ? "list active" : "list"}>
            {seasons?.map((item, index) => (
              <div className="item" key={index} onClick={()=>{setDetails(item?.videos);setSeasonName(item?.season)}}>
                <span>{item?.season}</span>
              </div>
            ))}
           
            
          </div>
        </div>
          <Arrow className={seasonButton ? "active" : "no-active"} />
        </div>
      )}
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={10}
        slidesPerView={4}
        navigation={true}
        watchSlidesProgress
        loop={false}
        breakpoints={{
          320: {
            slidesPerView: thumbnailOrientation==="PORTRAIT"?3:1,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: thumbnailOrientation==="PORTRAIT"?4:2,
            spaceBetween: 10,
          },
          980: {
            slidesPerView:  projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT:4,
            spaceBetween: 10,
          },
        }}
      >
        {details?.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="show" onClick={()=>similarVideos?setVideoPlayer(null):watchVideoHandler(item)}>
              <ShowCard
                season={season ? true : false}
                data={item}
                imageUrl={true}
                metaData={metaData ? true : false}
                type={type}
                showName={showName}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ShowsRow;
