import { fetchApiDataV2,fetchApiData } from "../../utils/utils"

export const  getShowsByCategory=async (appInfo)=>{
    const api=`home`
    return await fetchApiDataV2(appInfo,api)
}

export const getLiveInfo = async (appInfo)=>{
    const api=`fastchannel/${appInfo?.projectDetails?.projectConfig?.config?.FAST_CHANNEL_ID}`
      return await fetchApiData(appInfo,api)
    }
  
    export const getEventInfo = async (appInfo,eventId)=>{
      const api=`event/${eventId}`
      return await fetchApiData(appInfo,api)
    }



