import React ,{useState} from "react";
import { useSelector ,useDispatch  } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Link} from "react-router-dom";
import * as service from "../../network/service";
import { getAccessToken } from "../../Redux/AToken/ATokenSlice";
import { getUserDetails } from "../../Redux/UserDetailsSlice/UserDetailsSlice";
import { getSessionId, handleChangeColor } from "../../utils/utils";
import { getSessionIdValue } from "../../Redux/SessionId/sessionIdSlice";
import { toast } from "react-toastify";

const HeaderAccountsPage = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const redirectPage =projectInfo?.projectConfig?.config?.INITIAL_PAGE
  const appInfo = {
    projectDetails: projectInfo,
  };
  const [loading, setLoading] = useState(true);

  const [isAuthenticated, SetIsAuthenticated] = useState(false);
  const getAuthentication = async (userId) => {
    try {
      const authenticationResponse = await service.authenticate2(appInfo);
      if (
        authenticationResponse?.status === 200 &&
        authenticationResponse?.data?.message !== "Invalid user"
      ) {
        dispatch(
          getAccessToken({
            accessToken: authenticationResponse?.data?.token,
          })
        );
        dispatch(
          getUserDetails({
            userDetails: authenticationResponse?.data?.first_name,
          })
        );
        SetIsAuthenticated(true);
        setLoading(false);
        const sessionId = await getSessionId(appInfo);
        dispatch(
          getSessionIdValue({
            sessionId: sessionId,
          })
        );
        appInfo["sessionId"] = await sessionId;
        appInfo["accessToken"] = await authenticationResponse?.data?.token;
        if (!localStorage.getItem("applaunch")) {
          updateAppLaunch();
        }
        if (!localStorage.getItem("device_analytics")) {
          updateDeviceAnalytics(userId, appInfo);
        }
      }
      else{
        toast.error("Invalid User", {
          position: "bottom-center",
        });
      }
    } catch (err) {}
  };
  const updateAppLaunch = async () => {
    try {
      const response = await service.applaunchEvent("POP01", appInfo);
      if (response?.status === 200) {
        localStorage.setItem("applaunch", true);
      } else {
        localStorage.removeItem("applaunch");
      }
    } catch (err) {
      localStorage.removeItem("applaunch");
    }
  };
  const updateDeviceAnalytics = async (userId) => {
    try {
      const response = await service.analytics2(userId, appInfo);
      if (response?.status === 200) {
        localStorage.setItem("device_analytics", "true");
      } else {
        localStorage.removeItem("device_analytics");
      }
    } catch (err) {
      localStorage.removeItem("device_analytics");
    }
  };
  return (
    <>
    <header className="accountsPageHeader">
      <div className="left">
        <div className="logoContainer">
          <Link to={redirectPage==="LOGIN"?"/login":"/home"}>
            <h1>
              <img src={projectInfo?.projectConfig?.config?.LOGO} alt="Logo" />
            </h1>
          </Link>
        </div>
      </div>
      <div className="right">
        {location?.pathname === "/register" ? (
          <button onClick={() => navigate("/login")}>Login</button>
        ) : (
          <button onClick={() => navigate("/register")}>Register</button>
        )}
      </div>
    </header>
    </>
  );
};
export default HeaderAccountsPage;
