import { fetchApiData, fetchApiDataV2 } from "../../utils/utils"

export const getShowDetails=async (appInfo,showId)=>{
    
    const api=`show/${showId}`
        return await fetchApiDataV2(appInfo,api)   
}
export const getSimilarVideos=async (appInfo,showId)=>{
    const api=`show/similar/${showId}`
        return await fetchApiDataV2(appInfo,api)  
}


export const getVideoDetails = async (appInfo,id) =>{
    const api =`video/${id}`
  
    return await fetchApiDataV2(appInfo,api)}

export const getLiveInfo = async (appInfo)=>{
        const api="channel/list"
        if(appInfo?.projectDetails?.projectConfig?.config?.MULTI_LANGUAGE_REQUIRED==="true"){
            return await fetchApiData(appInfo,api)
        }else{
            return await fetchApiData(appInfo,api)
        }
        
      }


