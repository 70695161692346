import React, { useEffect, useState } from "react";
import { ReactComponent as PlayIcon } from "../../../assets/Icons/play.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import { ReactComponent as PremiumIcon } from "../../../assets/Icons/premium.svg";

import { EffectFade, Thumbs, Autoplay } from "swiper";
import { Link, useNavigate } from "react-router-dom";
import { imageUrlCheck } from "../../../utils/utils";

const Featured = ({ data }) => {
  const bgImage = "https://wallpapercave.com/wp/vDy3sUb.jpg";
  const [activeThumb, setActiveThumb] = useState();
  const [activeThumbLeft, setActiveThumbLeft] = useState();
  const navigate = useNavigate();
  return (
    <div className="featured">
      <Swiper
        onSwiper={setActiveThumb}
        slidesPerView={1}
        watchSlidesProgress
        effect={"fade"}
        spaceBetween={0}
        loop={false}

        className="mySwiper"
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[EffectFade, Thumbs, Autoplay]}
        allowTouchMove={false}
        // allowSlideNext={false}
        // allowSlidePrev={false}
      >
        {data?.shows?.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="bgImageContainer">
              {
                imageUrlCheck(item?.banner)?(

                  <img src={item?.banner} alt="BgImage" />
                ):(
                  <img src={`${process.env.REACT_APP_IMAGE_URL}${item?.banner}`} alt="BgImage" />

                )
              }
            </div>
            <div className="contents">
              <h1 className="title">{item?.show_name}</h1>
              <span className="line"></span>
              <div className="containers">
                
                <div className="right">
                  <div className="metaData">
                    <div className="datas">
                      <span className="year">{item?.year}</span>
                      <span className={`duration ${item?.year && 'showDot'}`}>{item?.duration_text}</span>
                      <span className="certificate">{item?.rating}</span>
                      <div className="genres">
                        {/* {
                          item?.category_names?.map((item,index)=>(
                            <span key={index}>{item}</span>

                          ))
                        } */}
                      </div>
                    </div>

                    <div
                      className="buttonContainer"
                      onClick={() =>
                        navigate(`/show-details/${item?.vanity_url}`, {
                          state: { showId: item?.show_id },
                        })
                      }
                    >
                      <PlayIcon />
                      <span>Watch Now</span>
                      {item?.is_free_video ? (
                        <span className="free">FREE</span>
                      ) : (
                        <PremiumIcon />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottomGradient" />
          </SwiperSlide>
        ))}
      </Swiper>

      
    </div>
  );
};

export default Featured;
