import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as FacebookIcon } from "../../assets/Icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/Icons/twitter.svg";
import { ReactComponent as Closeicon } from "../../assets/Icons/closeFilled.svg";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play.svg";

import * as service from "./service";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import "react-toastify/dist/ReactToastify.css";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  authenticate2,
  getPlayerToken,
  getUserSubscription,
  updateWatchlistData,
  authenticate,
} from "../../network/service";
import VideoJS from "../../Components/VideoJsPlayer/VideoJS";

import ShowsRow from "../../Components/ShowsRow/ShowsRow";
import { toast, ToastContainer } from "react-toastify";
import WatchWithoutAdsModal from "../../Components/Modals/WatchWithoutAdsModal";
import LogoutAllModal from "../../Components/Modals/LogoutAllModal";
import { checkOperatingSystem, getEventInfo } from "../../utils/utils";
import {
  convertAdUrl,
  imageUrlCheck,
  onVideoPlayFunction,
} from "../../utils/utils";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import { getAccessToken } from "../../Redux/AToken/ATokenSlice";
import Loading from "../../Components/Loading/Loading";
import ContinueWatchingModel from "../../Components/Modals/ContinueWatchingModel";
import useScrollPosition from "../../Components/ScrollPosition/useScrollPosition";
import PlayNextEpisode from "../../Components/Modals/PlayNextEpisode";
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import RelatedVideosContainer from "./Components/RelatedVideosContainer";
import { getVideoDetails } from "./service";
import { getVideoSubscription } from "../Subscription/service";
import Select from "react-select";
import { getMoreInfoOther } from "../../Redux/MoreInfo/moreInfoOtherSlice";
import { getMoreInfo } from "../../Redux/MoreInfo/moreInfoSlice";
import VideoPlayer from "./Components/Videoplayer";
import Comment from "./Components/Comment";
import { getLiveInfo } from "./service";
import TrailerModal from "../../Components/Modals/TrailerModal";
import Autoplay from "../../Components/Autoplay/Autoplay";


let debounce = true;
var videoStarted = false;
let seconds = 60;
let prevTime;
let videoPlaying = false;
const PlayerDetailsScreen = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const sessionId = useSelector((state) => state?.sessionId?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const isMoreInfoOther = useSelector((state) => state?.moreInfoOther?.value);
  const userSubscriptionDetails = useSelector(
    (state) => state?.userSubscription?.value
  );
  const [isShareActive, setIsShareActive] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [videoPlay, setVideoPlay] = useState(false);
  const [isContinueWatchingModal, setIsContinueWatchingModal] = useState(false);
  const [watchDuration, setWatchDuration] = useState("");
  const [pageURL, setPageURL] = useState(0);
  const [showDetails, setShowDetails] = useState({});
  const [similarVideos, setSimilarVideos] = useState([]);
  const [playerToken, setPlayerToken] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoPlayer, setVideoPlayer] = useState();
  const [watchlistStatus, setWatchlistStatus] = useState();
  const [watchWithoutAds, setWatchWithoutAds] = useState(false);
  const [selectedVideoDetails, setSelectedVideoDetails] = useState();
  const [logoutModal, setLogoutModal] = useState(false);
  const [categories, setCategories] = useState("");
  const [trailerModal, setTrailerModal] = useState(false);
  const [modalType, setModalType] = useState({ type: "logoutAll" });
  const [adUrl, setAdUrl] = useState("");
  const [initialVideoSubscriptions, setInitialVideoSubscriptions] = useState(
    []
  );
  const [loading, setLoading] = useState(true);
  const [skeleton, setSkeleton] = useState(false);
  const [subscribedUser, setSubscribeduser] = useState(false);
  const [browserPipMode, setBrowserPipMode] = useState(false);
  const [pipMode, setPipMode] = useState(false);
  const [nowPlaying, setNowPlaying] = useState("");
  const [episodes, setEpisodes] = useState();
  const [nextEpisodeModal, setNextEpisodeModal] = useState(false);
  const [isFreeVideo, setIsFreeVideo] = useState(false); // for inital free video checking for showing button name "subscribe" ,"watchNow" or "watch for free"
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [directSubscribeState, setDirectSubscribeState] = useState({
    flag: false,
    data: null,
  });
  const [subscribeButton, setSubscribeButton] = useState();
  const [subtitleInfo, setSubTitleInfo] = useState();
  const [closedCaptionInfo, setClosedCaptionInfo] = useState();
  const [countinueWatchingVideoPlayedStatus, setCountinueWatchingVideoPlayedStatus] = useState(false);
  const [watchedTime, setWatchedTime] = useState(0)
  const [watchedDuration, setWatchedDuration] = useState();
  const [multiLanguageOptions, setMultiLanguageOptions] = useState([])
  const [videoDetails, setVideoDetails] = useState();
  const [videoTitle, setVideoTitle] = useState("")
  const [upNext, setUpNext] = useState([])
  const [subscriptions, setSubscriptions] = useState();
  const [watchListStatus, setWatchListStatus] = useState();
  const [isAutoplayView, setIsAutoplayView] = useState(false);
  const [selectedAudioLanguage, setSelectedAudioLanguage] = useState({})
  const [videoId, setVideoId] = useState();
  const [userSubscriptionData, setUserSubscriptionData] = useState([]);
  const [isSubscribedUser, setIsSubscribedUser] = useState();
  const [audiolanguageInfo, setAudiolanguageInfo] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState()
  const [currentLanguageId, setCurrentLanguageId] = useState()

  const position = useScrollPosition();
  const location = useLocation();
  const showId = location?.state?.showId;
  const type = location?.state?.type;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let shareRef = useRef(null);
  let reportRef = useRef(null);
  let shareIconRef = useRef(null);
  let shareTwoRef = useRef(null);
  let reportTwoRef = useRef(null);
  let shareIconTwoRef = useRef(null);
  const scrollRef = useRef(null);
  let episodeCount = 0;
  const isLive = location?.state?.isLive;
  const eventId = location?.state?.eventId;
  // const videoId = location?.state?.videoDetails?.video_id;

  let showUniqueId = isMoreInfoOther?.showId ? isMoreInfoOther?.showId : showId;

  const videoType = (location?.state?.showDetails?.type == "linear_event" || location?.state?.type == "linear_event") ? "event" : "video";


  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
    sessionId,
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      color:'white',
      '&:hover': {
        borderColor: 'grey',
      },

    }),

    menu: (provided) => ({
      ...provided,
      top: '100%',
      bottom: 'auto',
      backgroundColor: 'white',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '200px',
    }),

    singleValue: (provided) => ({
      ...provided,
      color: 'grey',
    }),

    placeholder: (provided) => ({
      ...provided,
      color: 'white',
    }),
  };
  const playerRef = useRef(null);
  useEffect(() => {
    window.scroll(0, 0);

    if (location?.pathname) {
      let vanityUrl = location?.pathname.split("/")[2];

      if (vanityUrl !== "null" && vanityUrl !== "undefined" && vanityUrl) {
        fetchShowDetailsByVanityUrl(vanityUrl);
      } else {
        fetchShowDetails();
      }

      if (showId) {
        fetchSimilarVideos();
      }
    }
  }, [accessToken, projectInfo, location?.pathname]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    setVideoPlayer(null);
    setLoading(true)
    setVideoDetails(null)
   
  }, [location?.pathname]);


  useEffect(() => {
    setVideoPlayer(null);
    if (videoUrl && !watchWithoutAds && !isContinueWatchingModal) {
      const videoJsOptions = {
        autoplay: videoPlay,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: [
          {
            src: `${videoUrl}`,
            type: "application/x-mpegURL",
          },
        ],

        // poster:require("../../../images/thumb.png")
      };
      setLoading(false);

      setSkeleton(false);
     
      setVideoPlayer(
        <VideoJS
          options={videoJsOptions}
          onReady={handlePlayerReady}
          adUrl={adUrl ? adUrl : null}
          subtitles={subtitleInfo}
          closedCaptions={closedCaptionInfo}
          audiolanguage={audiolanguageInfo}
          onLanguageChange={handleLanguageChange}
          selectedLanguage={selectedLanguage}
          currentLanguageId={currentLanguageId}
        />
      );
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [videoUrl, watchWithoutAds,isContinueWatchingModal]);
  useEffect(() => {
    setPageURL(window.location.href);
  }, []);
  useEffect(() => {
    if (videoDetails) {
      handleVideoPlayback();
    }
    if (isLive) {
      setLoading(true);
      fetchLiveInfo();
    }
    else if (eventId) {
      setLoading(true);
      fetchEventInfo();
    }

  }, [subscriptions, videoDetails]);



  useEffect(() => {
    if (showDetails) {


      if (!loading) {
        // executeSPcroll();
      }
      if (showDetails?.categories) {
        let categoryNames;
        showDetails?.categories?.map((item) => {
          categoryNames = categoryNames ? categoryNames + item?.category_name + "," : item?.category_name + ",";
        });
        setCategories(categoryNames);
      }
      if (showDetails?.watchlist_flag === 1) {
        setWatchlistStatus("added");
      } else {
        setWatchlistStatus("removed");
      }
      if (showDetails?.teaser_flag === 1) {
        if (checkOperatingSystem() !== "iPhone") {
          fetchPlayerToken();
        }
      }
      if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true") {
        if (showDetails?.single_video === 1) {
          setSelectedVideoDetails(showDetails?.videos && showDetails?.videos[0]);
          if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true") {
            if (
              (showDetails?.videos && showDetails?.videos[0]?.subscriptions?.length > 0) ||
              (showDetails?.subscriptions && showDetails?.subscriptions?.length > 0)
            ) {
              let isSubscribedUser;
              if (showDetails?.subscriptions?.length > 0) {
                isSubscribedUser =
                  userSubscriptionData?.data?.length > 0
                    ? subscriptionCheck(userSubscriptionData?.data, showDetails?.subscriptions)
                    : false;
              } else {
                isSubscribedUser =
                  userSubscriptionData?.data?.length > 0
                    ? subscriptionCheck(
                      userSubscriptionData?.data,
                      showDetails?.videos && showDetails?.videos[0]?.subscriptions
                    )
                    : false;
              }

              if (isSubscribedUser === true) {
                setIsSubscribedUser(true);
                setSubscribeButton(false);
              } else {
                setIsSubscribedUser(false);

                if (showDetails?.videos[0]?.free_video === true) {
                  // for inital free video checking for showing button name "subscribe" ,"play Now"
                  setSubscribeButton(false);

                  setIsFreeVideo(true);
                } else {
                  setSubscribeButton(true);

                  setIsFreeVideo(false);
                }
              }
            } else {
              setIsSubscribedUser(true);
              setSubscribeButton(false);
            }
          } else {
            setIsSubscribedUser(true);
            setSubscribeButton(false);
          }
        } else {
          if (showDetails?.videos && showDetails?.videos[0]?.videos && showDetails?.videos[0]?.videos[0]) {
            let video = showDetails?.videos[0]?.videos[0];
            setSelectedVideoDetails(showDetails?.videos[0]?.videos[0]);
            if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true") {
              if (video?.subscriptions?.length > 0 || showDetails?.subscriptions?.length > 0) {
                let isSubscribedUser;
                if (showDetails?.subsriptions?.length > 0) {
                  isSubscribedUser =
                    userSubscriptionData?.data?.length > 0
                      ? subscriptionCheck(userSubscriptionData?.data, showDetails?.subscriptions)
                      : false;
                } else {
                  isSubscribedUser =
                    userSubscriptionData?.data?.length > 0
                      ? subscriptionCheck(userSubscriptionData?.data, video?.subscriptions)
                      : false;
                }

                if (isSubscribedUser === true) {
                  setIsSubscribedUser(true);
                  setSubscribeButton(false);
                } else {
                  setIsSubscribedUser(false);

                  if (video?.free_video === true) {
                    // for inital free video checking for showing button name "subscribe" ,"play Now"
                    setSubscribeButton(false);

                    setIsFreeVideo(true);
                  } else {
                    setSubscribeButton(true);

                    setIsFreeVideo(false);
                  }
                }
              } else {
                setIsSubscribedUser(true);
                setSubscribeButton(false);
              }
            } else {
              setIsSubscribedUser(true);
              setSubscribeButton(false);
            }
          } else {
            let video = showDetails?.videos && showDetails?.videos[0];
            setSelectedVideoDetails(video);
            if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true") {
              if (video?.subscriptions?.length > 0 || showDetails?.subscriptions?.length > 0) {
                let isSubscribedUser;
                if (showDetails?.subscriptions?.length > 0) {
                  isSubscribedUser =
                    userSubscriptionData?.data?.length > 0
                      ? subscriptionCheck(userSubscriptionData?.data, showDetails?.subscriptions)
                      : false;
                } else {
                  isSubscribedUser =
                    userSubscriptionData?.data?.length > 0
                      ? subscriptionCheck(userSubscriptionData?.data, video?.subscriptions)
                      : false;
                }

                if (isSubscribedUser === true) {
                  setIsSubscribedUser(true);
                  setSubscribeButton(false);
                } else {
                  setIsSubscribedUser(false);

                  if (video?.free_video === true) {
                    // for inital free video checking for showing button name "subscribe" ,"play Now"
                    setSubscribeButton(false);

                    setIsFreeVideo(true);
                  } else {
                    setSubscribeButton(true);

                    setIsFreeVideo(false);
                  }
                }
              } else {
                setIsSubscribedUser(true);
                setSubscribeButton(false);
              }
            } else {
              setIsSubscribedUser(true);
              setSubscribeButton(false);
            }
          }
        }
      } else if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "false") {
        setSubscribeButton(false);
        if (userSubscriptionData?.login_needed === false) {
          setIsFreeVideo(true);
        } else {
          setIsFreeVideo(false);
        }
        if (showDetails?.single_video === 1) {
          setSelectedVideoDetails(showDetails?.videos[0]);
        } else {
          if (showDetails?.videos && showDetails?.videos[0]?.videos) {
            setSelectedVideoDetails(showDetails?.videos && showDetails?.videos[0]?.videos[0]);
          } else {
            setSelectedVideoDetails(showDetails?.videos && showDetails?.videos[0]);
          }
        }
      }
    }
  }, [showDetails]);
  useEffect(() => {
    if (showDetails?.watchlist_flag === 1) {
      setWatchlistStatus("added");
    } else {
      setWatchlistStatus("removed");
    }
    if (showDetails?.teaser_flag === 1) {
      let trailer = true;
      fetchPlayerToken(trailer);
      setNowPlaying("Trailer");
    }

    if (showDetails?.single_video === 0 || showDetails?.single_video === 1) {
      if (showDetails?.single_video === 1) {
        setSelectedVideoDetails(
          showDetails?.videos[0] && showDetails?.videos[0]
        );
        setInitialVideoSubscriptions(showDetails?.videos[0]?.subscriptions);
        if (showDetails?.videos[0]?.subscriptions?.length > 0) {
          const isSubscribedUser =
            userSubscriptionData?.data?.length > 0
              ? subscriptionCheck(
                userSubscriptionData?.data,
                showDetails?.videos[0]?.subscriptions
              )
              : false;

          if (isSubscribedUser === true) {
            setSubscribeduser(true);
          } else {
            setSubscribeduser(false);
            if (showDetails?.videos[0]?.free_video === true) {
              // for inital free video checking for showing button name "subscribe" ,"watchNow" or "watch for free"
              setIsFreeVideo(true);
            } else {
              setIsFreeVideo(false);
            }
          }
        } else {
          setSubscribeduser(true);
        }
      } else {
        let video = [];

        if (
          showDetails?.videos?.length === 1 &&
          showDetails?.videos[0]?.season
        ) {
          setEpisodes(showDetails?.videos[0]?.videos);
        } else if (!showDetails?.videos[0]?.videos) {
          setEpisodes(showDetails?.videos);
        }

        if (
          showDetails?.videos[0]?.videos &&
          showDetails?.videos[0]?.videos[0]
        ) {
          video = showDetails?.videos[0]?.videos[0];
        } else {
          video = showDetails?.videos && showDetails?.videos[0];


        }

        setSelectedVideoDetails(video);

        setInitialVideoSubscriptions(video?.subscriptions);
        if (video?.subscriptions?.length > 0) {
          const isSubscribedUser =
            userSubscriptionData?.data?.length > 0
              ? subscriptionCheck(
                userSubscriptionData?.data,
                video?.subscriptions
              )
              : false;

          if (isSubscribedUser === true) {
            setSubscribeduser(true);
          } else {
            if (video?.free_video === true) {
              // for inital free video checking for showing button name "subscribe" ,"watchNow" or "watch for free"
              setIsFreeVideo(true);
            } else {
              setIsFreeVideo(false);
            }
            setSubscribeduser(false);
          }
        } else {
          setSubscribeduser(true);
        }
      }
    }

    if (showDetails?.categories) {
      let categoryNames;
      showDetails?.categories?.map((item) => {
        categoryNames = categoryNames
          ? categoryNames + item?.category_name + ","
          : item?.category_name + ",";
      });
      setCategories(categoryNames);
    }
    if (showDetails) {
      if (showDetails?.videos)
        var updateDuration = showDetails?.videos[0]?.watched_duration;

      setWatchDuration(updateDuration);
    }
  }, [showDetails, location?.pathname]);

 
  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      if (selectedVideoDetails?.video_id) {
        try {
          const videoSubResponse = await fetchVideoSubscription(selectedVideoDetails.video_id);
  
          if (videoSubResponse?.status === 200) {
            

            setSubscriptions(videoSubResponse?.data?.data);
          } else {
            console.error('Failed to fetch video subscription details:', videoSubResponse);
          }
        } catch (error) {
          console.error('Error fetching video subscription details:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchSubscriptionDetails();
  }, [selectedVideoDetails]);

  // const executeScroll = () => {
  //   return scrollRef.current.scrollIntoView({ behavior: "smooth" });
  // };
  const handleSubscribe = (data) => {
    if (user) {
      localStorage.setItem("selectedAmount", data?.price);
      let vanityUrl = location?.pathname.split("/")[2];
      if (vanityUrl !== "null" && vanityUrl !== "undefined" && vanityUrl) {
        localStorage.setItem("vanityUrl", vanityUrl);
      } else {
        localStorage.setItem("showId", showId);
      }

      localStorage.setItem("selectedSubId", data?.subscription_id);
      navigate("/payment", {
        state: { subscription: { ...data, is_coupon: false } },
      });
    } else {
      navigate("/login", {
        state: { path: location?.pathname, showId: showId },
      });
    }
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    player.on("play", () => {
      let event = videoStarted == true ? "POP09" : "POP02";
      videoStarted = true;
      videoPlaying = true;
      updateVideoAnalytics(event, player.currentTime());
    });
    player.on("loadedmetadata", () => {
      // player.pause();
      if (watchedTime > 0) {
        player.currentTime(watchedTime.toString());
        player.play();
        videoPlaying = true;
      } else if (watchedDuration > 0) {
        player.currentTime(watchedDuration.toString());
        player.play();
        videoPlaying = true;
      }
      else {
        player.play();
        videoPlaying = true;
      }
    });
    player.on("timeupdate", function (e) {
      let event = "POP03";
      videoPlaying = true;
      var hasPlayedTime = player.currentTime();
      var intPlayedTime = parseInt(hasPlayedTime, 10);
      var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
      if (isSixty && debounce) {
        debounce = false;
        prevTime = intPlayedTime;
        updateVideoAnalytics(event, player.currentTime());
      } else {
        if (debounce == false && prevTime != intPlayedTime) {
          debounce = true;
        }
      }
    });

    player.on("pause", () => {
      let event = "POP04";
      videoPlaying = true;
      updateVideoAnalytics(event, player.currentTime());
    });
    player.on("ended", () => {
      let event = "POP05";
      videoPlaying = false;
      // episodeCount = findIndex() + 1;
      updateVideoAnalytics(event, player.currentTime());
      if (videoDetails?.up_next?.length > 0) {
        setIsAutoplayView(true);
      }
    });
    player.on("dispose", () => {
      videoPlaying = false;
      videoStarted = false;
      player.pause();
    });
    player.on('keydown', (e) => {
      if (e.which === 32 || e.which === 13) {
        e.preventDefault();
        if (player.paused()) {
          player.play();
        } else {
          player.pause();
        }
      }
    });

    player.on('keydown', (e) => {
      if (e.which === 37) {
        e.preventDefault();
        player.currentTime(player.currentTime() - 10);
      } else if (e.which === 39) {
        e.preventDefault();
        player.currentTime(player.currentTime() + 10);
      }
    });
    player.on('keydown', (e) => {
      if (e.which === 70 || e.which === 102) {
        e.preventDefault();
        player.requestFullscreen();
      }
    });

    player.on('keydown', (e) => {
      if (e.which === 38) {
        e.preventDefault();
        player.volume(player.volume() + 1);
      } else if (e.which === 40) {
        e.preventDefault();
        player.volume(player.volume() - 0.1);
      }
    });

    player.on('keydown', (e) => {
      if (e.which === 77 || e.which === 109) {
        e.preventDefault();
        player.muted(!player.muted());
      }
    });
    player.ready(() => {
      player.el().focus();
    });
  };
  const updateVideoAnalytics = async (event, time) => {
    const isLive = "0";
    const updateResponse = await onVideoPlayFunction(
      appInfo,
      selectedVideoDetails,
      event,
      time,
      isLive,
      categories,
      showDetails?.show_id
    );
  };
  const findIndex = () => {
    let elementIndex = null;
    if (episodes) {
      episodes?.filter((filteringItem, index) => {
        if (filteringItem?.video_id === selectedVideoDetails?.video_id) {
          elementIndex = index;
        }
      });
    }
    return elementIndex;
  };
  const fetchShowDetails = async () => {

    try {
      setLoading(true);
      const showDetailsResponse = await service.getShowDetails(appInfo, showId);
      if (showDetailsResponse?.status === 200) {
        setShowDetails(showDetailsResponse?.data?.data);
        if (showDetailsResponse?.data?.data?.teaser_flag !== 1) {
          setLoading(false);
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const fetchShowDetailsByVanityUrl = async (vanityUrl) => {

    try {
      setLoading(true);
      const showDetailsResponse = await service.getShowDetails(appInfo, vanityUrl);
      if (showDetailsResponse?.status === 200) {
        setShowDetails(showDetailsResponse?.data?.data);

        setVideoId(showDetailsResponse?.data?.data?.videos[0]?.videos[0]?.video_id);
        if (showDetailsResponse?.data?.data?.teaser_flag !== 1) {
          setLoading(false);
        }
        if (!showId) {
          fetchSimilarVideos(showDetailsResponse?.data?.data?.show_id);
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const fetchSimilarVideos = async (sid) => {
    let id = showId ? showId : sid;
    const similarVideosResponse = await service.getSimilarVideos(appInfo, id);
    if (similarVideosResponse?.status === 200) {
      setSimilarVideos(similarVideosResponse?.data?.data);
    }
  };
  const fetchVideoDetails = async (selectedVideoDetails) => {

    try {
      await fetchUserSubscriptionDetails();

      const vanityUrl = selectedVideoDetails?.vanity_url;

      const response = await getVideoDetails(appInfo, vanityUrl);
    
      setVideoDetails(response?.data?.data);
      if (response?.status === 200) {
    
        setCurrentLanguageId(response?.data?.data?.language_id)
        setMultiLanguageOptions(response.data.data.audio_languages);
      
          setVideoTitle(response?.data?.data?.video_title)
          setUpNext(response?.data?.data?.up_next)
          
          setLoading(false)
          if (response?.data?.data?.watchlist_flag === 1) setWatchListStatus("added");
        
         else {
          setWatchListStatus("removed");
        }
      }


    } catch (err) {
      setLoading(false);
    }
  };
  let language_VideoID;
  const handleLanguageChange = async (selectedLanguageProps) => {

    setSelectedLanguage(selectedLanguageProps.language_name)
    language_VideoID = selectedLanguageProps.video_id;

    // let eventChange = "POP03"

    // const updateResponse = await onVideoPlayFunction(
    //   appInfo,
    //   selectedLanguageProps,
    //   eventChange,
    //   timeState,
    //   "0",
    //   location?.state?.categories,
    //   showDetails?.show_id
    // );
    // if (updateResponse.status == 200) {
    fetchVideoDetails(language_VideoID);
    // }

  };
  const fetchUserSubscriptionDetails = async () => {
    const userSubResponse = await getUserSubscription(appInfo);
    if (userSubResponse?.status === 200) {

      setUserSubscriptionData(userSubResponse?.data);
      dispatch(
        getUserSubscriptionData({
          userSubscription: userSubResponse?.data,
        })
      );
    }
  };
  const fetchVideoSubscription = async (videoId) => {
    const type = "video";
    try {
      return await getVideoSubscription(appInfo, videoId, type);
    } catch (err) { }
  };
  const watchVideoHandler = async (selectedVideoDetails, directSubscribe, directSubscribeData) => {

    // setSelectedVideoDetails({ ...selectedVideoDetails, showId: showId });
    await fetchVideoDetails(selectedVideoDetails);
    // playVideo();
    
  };

  const multiLanguageDropdownHandler = async (selectedOption) => {
    setSelectedAudioLanguage({ value: selectedOption.value, label: selectedOption.label, video_name: selectedOption.video_name })
    setWatchedTime(playerRef.current.currentTime())
    setVideoTitle(selectedOption.video_title)
    createVideoUrl(selectedOption)
  }
  const fetchLiveInfo = async () => {
    try {
      const response = await getLiveInfo(appInfo);
      setVideoUrl(response?.data?.data[0]?.live_link)
    } catch (err) {
      setLoading(false)
    }
  }

  const fetchEventInfo = async () => {
    try {
      const response = await getEventInfo(appInfo, eventId);
      setVideoUrl(response?.data?.data?.live_url)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const handleVideoPlayback = async () => {
    if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true" && !user) {
      navigate("/login", { state: { ...location.state, path: location?.pathname, showId: showId, isFrom: true } });
      return;
    }
    if (logoutLimitCheck()) {
      return;
    }
    localStorage.setItem("vanityUrl", showDetails?.vanity_url);
    if ( subscriptions && subscriptions.length < 1) {

      if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true") {
        if (!user) {
          navigate("/login", { state: { ...location.state, path: location?.pathname, showId: showId, isFrom: true } });
        }
        else if (projectInfo?.projectConfig?.config?.AD_REQUIRED === "true") {
          setAdUrl(convertAdUrl(videoDetails, showDetails, appInfo, isLive));
          
          playVideo();
        } else {
          playVideo();
        }
      }
      else if (projectInfo?.projectConfig?.config?.AD_REQUIRED === "true") {
        if (userSubscriptionData?.login_needed === false) {
          handleClose();
          setAdUrl(convertAdUrl(videoDetails, showDetails, appInfo, isLive));
          playVideo();
        } else if (userSubscriptionData?.login_needed === true) {
          setModalType({ type: "videoWatchLimitExpired" });
          setLogoutModal(true);
        }
        else {
          setAdUrl(convertAdUrl(videoDetails, showDetails, appInfo, isLive));
          playVideo();
        }
      }
      else {
        if (userSubscriptionData?.login_needed === false) {
          handleClose();
          playVideo();
        } else if (userSubscriptionData?.login_needed === true) {
          setModalType({ type: "videoWatchLimitExpired" });
          setLogoutModal(true);
        }
        else {
          playVideo();
        }
      }
    }
    else if (subscriptions?.length > 0 && videoDetails?.free_video) {

      logoutLimitCheck();
      if (subscriptionCheck(userSubscriptionData?.data, subscriptions)) {
        playVideo();
      } else {
        setWatchWithoutAds(true);
      }
    }
    else {

      logoutLimitCheck();
      if (subscriptionCheck(userSubscriptionData?.data, subscriptions)) {
        playVideo();
      } else {
        if (!user) {

          navigate("/login", { state: { ...location.state, path: location?.pathname, showId: showId, isFrom: true } });
        }
        else {
          navigate("/subscription", { state: { videoId: videoDetails?.video_id } });
        }
      }

    }
  };


  const logoutLimitCheck = () => {
    if (userSubscriptionData?.forcibleLogout === true) {
      setLogoutModal(true);
      setModalType({
        type: "logoutAll",
        heading: "You’ve reached the maximum Device limit.Do you want to logout from all devices",
      });
      return true;
    } else if (userSubscriptionData?.session_expired === true) {
      setModalType({ type: "redirect" });
      setLogoutModal(true);
      setTimeout(() => {
        handleClose();
        redirectToLogin();
      }, 1000);
      return true;
    }
    return false;
  }

  const trailerHandler = (flag) => {
  
    if (flag === "close") {
      setTrailerModal(false);
      if (checkOperatingSystem() !== "iPhone") {
        playerRef?.current?.play();
      }
    } else {
      setTrailerModal(true);
      if (checkOperatingSystem() !== "iPhone") {
        playerRef?.current?.pause();
      }
    }
  };
  const createVideoUrl = async (video) => {
    const response = await getVideoDetails(video?.value, appInfo);
    setUpNext(response?.data?.data?.up_next)
    const arr = video.video_name?.split("/").reverse();
    const playerTokenData = await fetchPlayerToken(arr[1]);

    if (playerTokenData) {
      setVideoPlayer(null);
      setVideoUrl(
        "https://api.gizmott.com/api/v1/playlistV2/playlist.m3u8?id=" +
        arr[1] +
        "&token=" +
        playerTokenData +
        "&type=" +
        videoType +
        "&pubid=" +
        projectInfo?.projectConfig?.pubid
      );
    }
  }

  const playVideo = async ( watchedLength) => {
    if (watchedLength) {
      setWatchedDuration(watchedLength);
    }
    if(videoDetails?.watched_duration > 0)
    {
      setIsContinueWatchingModal(true);
    }
    setSubTitleInfo(videoDetails?.subtitles);
    setClosedCaptionInfo(videoDetails?.closed_captions)
    setAudiolanguageInfo(videoDetails.audio_languages);
    if (!videoDetails || !videoDetails.video_name) {
      return;
    }

    const arr = videoDetails.video_name.split("/").reverse();
    if (arr.length < 2) {
      console.error("video_name does not contain the expected format");
      return;
    }
    const playerTokenData = await fetchPlayerToken(arr[1]);

    if (playerTokenData) {
      setVideoPlayer(null);
      setVideoUrl(
        "https://api.gizmott.com/api/v1/playlistV2/playlist.m3u8?id=" +
        arr[1] +
        "&token=" +
        playerTokenData +
        "&type=" +
        videoType +
        "&pubid=" +
        projectInfo?.projectConfig?.pubid
      );
    }

    if (watchedLength) {
      setWatchedDuration(watchedLength);
    }
    setSubTitleInfo(videoDetails?.subtitles);
  };


  const handleClose = () => {
    document.body.className = "hideScroll";
    if (isMoreInfoOther?.isModal) {
      if (isMoreInfoOther?.isFromSearch) {
        if (location?.state?.isFromHome) {
          navigate("/home");
        }
        setTimeout(() => {
          dispatch(
            getMoreInfoOther({
              isModal: false,
              showId: null,
              isFromSearch: false,
            })
          );
        }, 600);
      } else {
        setTimeout(() => {
          dispatch(
            getMoreInfoOther({
              isModal: false,
              showId: null,
            })
          );
        }, 600);
      }
    } else {
      setTimeout(() => {
        dispatch(
          getMoreInfo({
            moreInfo: false,
          })
        );
      }, 500);
    }

  };
  const subscriptionCheck = (userSub, videoSub) => {
    return userSub?.some((user) => videoSub?.some((video) => user?.sub_id === video?.subscription_id));
  };
  const redirectToLogin = () => {
    dispatch(
      getUser({
        user: null,
      })
    );
    localStorage.removeItem("userId");
    tokenAuthenticate();
    navigate("/login", {
      state: { path: location?.pathname, showId: showUniqueId },
    });
  };
  const tokenAuthenticate = async () => {
    const response = await authenticate(appInfo);
    if (response?.status === 200) {
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
    }
  };
  const fetchPlayerToken = async (videoId) => {
    const playerTokenResponse = await getPlayerToken(appInfo, videoId);
    if (playerTokenResponse?.status === 200) {

      return playerTokenResponse?.data?.data;
    }
  };


  const addToWatchlist = (showId) => {
    if (user) {
      updateWatchlist(showId, 1);
    } else {
      navigate("/login", { state: { path: location?.pathname, showId: showId } });
    }
  };
  const removeFromWatchlist = (showId) => {
    updateWatchlist(showId, 0);
  };
  const updateWatchlist = async (showId, flag) => {
    try {
      const response = await updateWatchlistData(appInfo, showId, flag);

      if (response?.status === 200) {
        if (flag === 1) {
          toast.success("Added to My List", {
            position: "bottom-center",
            toastId: "success1",
          });
          setWatchlistStatus("added");
        } else {
          toast.success("Removed from My List", {
            position: "bottom-center",
          });
          setWatchlistStatus("removed");
        }
      }
    } catch (err) {
    }
  };
  const searchHandler = (value, type) => {
    navigate(`/search?query=${value}`, { state: { type } });
  };
  const resetValues = () => {
    setIsReport(false)
  }

  return (
    <div className="playerdetailsScreen" onClick={() => resetValues()}>

      {watchWithoutAds && (
        <WatchWithoutAdsModal
          setWatchWithoutAds={setWatchWithoutAds}
          showDetails={showDetails}
          appInfo={appInfo}
          setAdUrl={setAdUrl}
          videoDetails={videoDetails}
          playVideo={playVideo}
          setIsContinueWatching={setIsContinueWatchingModal}
          showDuration={watchDuration}
          videoPlay={setVideoPlay}
          directSubscribeState={directSubscribeState}
          // handleSubscribe={handleSubscribe}
          watchDuration={watchedDuration}
        />
      )}
      {nextEpisodeModal && (
        <PlayNextEpisode
          episodes={episodes}
          findIndex={findIndex}
          watchVideoHandler={watchVideoHandler}
          setNextEpisodeModal={setNextEpisodeModal}
        />
      )}

      {isContinueWatchingModal && (
        <ContinueWatchingModel
          setIsContinueWatchingModal={setIsContinueWatchingModal}
          videoDetails={videoDetails}
          playVideo={playVideo}
          // playContinueVideo={playContinueVideo}
          watchDuration={watchDuration}
          showDetails={showDetails}
          appInfo={appInfo}
          setAdUrl={setAdUrl}
        />
      )}
      {logoutModal && (
        <LogoutAllModal
          showId={showId}
          appInfo={appInfo}
          setLogoutModal={setLogoutModal}
          modalType={modalType}
          videoDetails={selectedVideoDetails}
          handleClose={handleClose}
    
        />
      )}

      {trailerModal && (
         <TrailerModal
         teaser={showDetails?.teaser}
         showDetails={showDetails}
         trailerHandler={trailerHandler}
         appInfo={appInfo}
       />         
        )}
      <div className="wrapper">
      <ToastContainer/>
        <div className="leftContainer">
          <div className="videoplayerContainer">


            {videoPlayer && (
              <div
                className={
                  pipMode === false
                    ? "videoContainer"
                    : position > 700 && browserPipMode === false && videoPlaying === true
                      ? "videoContainer heightIncrease"
                      : "videoContainer"
                }
              >
                <div
                  className={
                    position > 700 && browserPipMode === false && videoPlaying === true && pipMode === true
                      ? "player pipMode"
                      : "player"
                  }
                >
                  <div className="gradientTop"></div>
                  <div className="closeIcon" onClick={() => setPipMode(false)}>
                    <Closeicon />
                  </div>

                  {/* <div className="nowPlaying">
                <h1>Now Playing : {nowPlaying}</h1>
              </div> */}

                  {videoPlayer && videoPlayer}
                  {
                    isAutoplayView &&
                    <Autoplay episodes={videoDetails?.up_next} setIsAutoplayView={setIsAutoplayView} showName={showDetails?.vanity_url} watchVideoHandler={watchVideoHandler}/>
                  }
                </div>
              </div>
            )}

          </div>

          <div className="multiAudio" >
            {multiLanguageOptions?.length > 1 &&
              <div className="selectContainer">
                <Select
                  styles={customStyles}
                  options={multiLanguageOptions.map((video) => ({
                    value: video.video_id,
                    label: video.language_name,
                    video_name: video.video_name,
                    video_title: video.video_title
                  }))}
                  placeholder={"Choose Language..."}
                  onChange={multiLanguageDropdownHandler}
                  value={selectedAudioLanguage && Object.keys(selectedAudioLanguage)?.length !== 0 ? selectedAudioLanguage : null}
                />
              </div>}
          </div>
          <div className="infoContainer">
            <div className="showMetadata">
              <div className="actions">
                <div className="left">
                  <div className="playButtonContainer">
                    <button
                      className="playButton"
                      onClick={() => watchVideoHandler(selectedVideoDetails)}
                    >
                      {"Play Now"}
                    </button>
                  </div>
                </div>

                <div className="right">
                  {watchlistStatus === "added" ? (
                    <div
                      className="addRemoveList"
                      onClick={() => removeFromWatchlist(showDetails?.show_id)}
                    >
                      <button>Remove From mylist
                        <div className="icon">
                          <RemoveCircleOutlineIcon sx={{ fontSize: "15px" }} />

                        </div>
                      </button>
                    </div>
                  ) : (
                    <div
                      className="addRemoveList"
                      onClick={() => addToWatchlist(showDetails?.show_id)}
                    >
                      <button className="list">Add to mylist
                        <div className="icon">
                          <AddIcon sx={{ fontSize: "16px" }} />

                        </div>
                      </button>
                    </div>
                  )}

                  <div className="shareButton">
                    <button
                      className="share"
                      onClick={() => {
                        setIsShareActive(!isShareActive);
                        setIsReport(false);
                      }}
                      ref={shareIconTwoRef}
                    >
                      Share
                      <div className="icon">
                        <SendIcon sx={{ fontSize: "14px" }} />

                      </div>
                    </button>
                    <div
                      className={
                        isShareActive
                          ? "active shareContainer"
                          : "shareContainer"
                      }
                      ref={shareTwoRef}
                    >
                      <div
                        className="facebookContainer"
                        onClick={() => setIsShareActive(false)}
                      >
                        {/* <div className="left"> */}
                        <FacebookShareButton
                          url={pageURL}
                          quote={"Share"}
                          className="facebook"
                        >
                          Facebook
                          <div className="icon">
                            <FacebookIcon />
                          </div>
                        </FacebookShareButton>
                        {/* </div> */}
                        {/* <div className="right">
                        <FacebookIcon />
                      </div> */}
                      </div>
                      <div
                        className="twitterContainer"
                        onClick={() => setIsShareActive(false)}
                      >
                        <TwitterShareButton url={pageURL} className="twitter">
                          Twitter
                          <div className="icon">
                            <TwitterIcon />
                          </div>
                        </TwitterShareButton>
                      </div>
                    </div>
                  </div>

                  <div className="more">
                    <button
                      className="more"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsReport(!isReport);
                        setIsShareActive(false);
                      }}
                      ref={reportRef}
                    >
                      {isReport ? <Closeicon /> : <MoreHorizIcon ref={reportTwoRef} />}
                    </button>
                    {isReport ? (
                      <div
                        className={
                          isReport
                            ? "reportProblemContainer active"
                            : "reportProblemContainer"
                        }
                        onClick={() => navigate("/contact-support")}
                      >
                        <span>Report a problem</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <h1 className="name">{showDetails?.show_name}</h1>
              <div className="data">
                <span className="year">{showDetails?.year}</span>
                <span className={`duration ${showDetails?.year && "showDot"}`}>
                  {showDetails?.duration_text}
                </span>
                <span className="certificate">{showDetails?.rating}</span>
              </div>
              <div className="genres">
                {showDetails?.categories?.map((item, index) => (
                  <span key={index}>{item?.category_name}</span>
                ))}
              </div>


              <p className="description">
                {showDetails?.synopsis || showDetails?.video_description}
              </p>
              <div className="extraInfoContainer">
             
                {showDetails?.video_tags?.length > 0 && (
                  <div className="metaItem">
                    <h6 className="subHeading">Tags</h6>
                   
                      <div className="items oneRow">
                      {showDetails?.video_tags?.length > 0 &&
                        showDetails?.video_tags?.map((item, index) => (
                          <span className="item" onClick={() => searchHandler(item, "tags")}>
                            {item}
                            <span className="comma">,</span>
                          </span>
                        ))}
                    </div>
                  
                  </div>
                )}
            </div>
              <div className="info">
                {showDetails?.show_cast && (
                  <div className="starring">
                    <div className="left">
                      <h6>Starring</h6>
                    </div>
                    <div className="right">
                      <ul className="names">
                        <li>{showDetails?.show_cast}</li>
                      </ul>
                    </div>
                  </div>
                )}
                {showDetails?.director && (
                  <div className="director">
                    <div className="left">
                      <h6>Directed by</h6>
                    </div>
                    <div className="right">
                      <h6>{showDetails?.director}</h6>
                    </div>
                  </div>
                )}
              </div>
              {showDetails?.teaser_flag === 1 && (
              <div className="showTrailerContainer">
                <h6 className="heading">Trailer</h6>
                <div className="imageContainer" onClick={() => trailerHandler("open")}>
                  {imageUrlCheck(showDetails?.logo_thumb) ? (
                    <img src={showDetails?.logo_thumb} alt="Thumbnail" />
                  ) : (
                    <img src={`${process.env.REACT_APP_IMAGE_URL}${showDetails?.logo_thumb}`} alt="Thumbnail" />
                  )}
                  <div className="playIconContainer">
                    <PlayIcon />
                  </div>
                </div>
              </div>
            )}
              {!userSubscriptionDetails?.data?.length > 0 || selectedVideoDetails?.free_video === true ? (
                showDetails?.subscriptions?.length > 0 || selectedVideoDetails?.free_video === true ? (
                  <div className="subscriptions">
                    {showDetails?.subscriptions?.map((item, index) => (
                      <div className="items" key={index} onClick={() => handleSubscribe(item)}>
                      {/* <div className="items" key={index} > */}
                        {item?.subscription_text}
                      </div>
                    ))}

                    {showDetails?.single_video === 1 && selectedVideoDetails?.free_video === true && (

                      <div className="items free" onClick={() => watchVideoHandler(selectedVideoDetails)}>

                        Free
                      </div>
                    )}
                  </div>
                ) : null
              ) : null}
            </div>
            {showDetails?.single_video === 0 &&
              showDetails?.videos?.length > 1 &&
              showDetails?.videos[0]?.videos && (
                <ShowsRow
                  season={true}
                  showName={showDetails?.vanity_url}
                  data={showDetails?.videos}
                  metaData={false}
                  watchVideoHandler={watchVideoHandler}
                  type="episodes"
                  thumbnailOrientation={thumbnailOrientation}
                />
              )}
            {showDetails?.single_video !== 1 &&
              showDetails?.videos?.length === 1 &&
              showDetails?.videos[0]?.season && (
                <ShowsRow
                  season={false}
                  showName={showDetails?.vanity_url}
                  data={showDetails?.videos[0]?.videos}
                  title={"Episodes"}
                  metaData={false}
                  watchVideoHandler={watchVideoHandler}
                  type="episodes"
                  thumbnailOrientation={thumbnailOrientation}
                />
              )}
            {showDetails?.single_video === 0 &&
              !showDetails?.videos[0]?.videos && (
                <ShowsRow
                  season={false}
                  showName={showDetails?.vanity_url}
                  data={showDetails?.videos}
                  title={"Episodes"}
                  metaData={false}
                  watchVideoHandler={watchVideoHandler}
                  type="episodes"
                  thumbnailOrientation={thumbnailOrientation}
                />
              )}
          </div>
          <div className="commentsSection">
           
            {videoPlayer &&
              projectInfo?.projectConfig?.config?.COMMENTS_REQUIRED === "true" &&
              <div className="wrapper">
                <Comment videoId={videoDetails?.video_id || selectedVideoDetails?.video_id} projectInfo={projectInfo} />
              </div>
            }
          </div>


        </div>

        <div className="rightContainer">
          <div className="relatedVideos">
            <RelatedVideosContainer
              setVideoPlayer={setVideoPlayer}
              similarVideos={similarVideos} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerDetailsScreen;
