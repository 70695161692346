import axios from "axios";
import moment from "moment";
const qs = require('query-string');

export const translateText = (languageModal, t, key) => {
  return languageModal?.selectedLanguageCode ? t(`${key}.${languageModal?.selectedLanguageCode}`) : ''
}

export const getSessionId = (appInfo) => {
  let date = new Date();
  let millis = date.getTime();
  let deviceId = appInfo?.projectDetails?.device_id;
  let sessionId = millis + deviceId;
  return sessionId
};

export const checkOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  //Check mobile device is Android
  if (/android/i.test(userAgent)) {
    //Add your Code here
    return "android";
  }

  //Check mobile device is IOS
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    //Add your Code here
    return "iPhone";
  }
  if (userAgent.match(/Linux/i)) {
    return 'Linux'
  }
  //Check device os is Windows (For Laptop and PC)
  if (navigator.appVersion.indexOf("Win") != -1) {
    //Add your Code here
    return "windows";
  }

  //Check device os is MAC (For Laptop and PC)
  if (navigator.appVersion.indexOf("Mac") != -1) {
    //Add your Code here
    return "mac";
  }
  return "none";
};

export const checkOsVersion = () => {
  const userAgent = navigator.userAgent;

  let osVersion = 'Unknown';

  if (/Windows NT/.test(userAgent)) {
    // Windows
    const match = /Windows NT (\d+\.\d+)/.exec(userAgent);
    if (match) {
      osVersion = match[1];
    }
  } else if (/Mac OS X/.test(userAgent)) {
    // macOS
    const match = /Mac OS X (\d+[_.]\d+(?:[_.]\d+)?)/.exec(userAgent);
    if (match) {
      osVersion = match[1].replace(/_/g, '.');
    }
  } else if (/Linux/.test(userAgent)) {
    // Linux
    const match = /Linux(?: ([^)]+))?/.exec(userAgent);
    if (match) {
      osVersion = match[1] || 'Unknown';
    }
  } else if (/Android/.test(userAgent)) {
    // Android
    const match = /Android (\d+(?:\.\d+)?)/.exec(userAgent);
    if (match) {
      osVersion = match[1];
    }
  } else if (/iPhone|iPad|iPod/.test(userAgent)) {
    // iOS
    const match = /OS (\d+(?:_\d+)?)/.exec(userAgent);
    if (match) {
      osVersion = match[1].replace(/_/g, '.');
    }
  }

  return osVersion;

}

export const imageUrlCheck = (url) => {
  if (/^https/.test(url)) {
    return true
  } else {
    return false
  }
}

export const rowItemCount = (count) => {
  let rowCount = ""
  switch (count) {
    case "3":
      rowCount = "rowCount3"
      break;
    case "4":
      rowCount = "rowCount4"
      break;
    case "5":
      rowCount = "rowCount5"
      break;
    case "6":
      rowCount = "rowCount6"
      break;
    case "7":
      rowCount = "rowCount7"
      break;
    case "8":
      rowCount = "rowCount8"
      break;
    default:
  }
  return rowCount
}

export const isCountryInEU = (countryCode) => {
  // List of EU member countries
  const euCountries = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE'];

  return euCountries.includes(countryCode.toUpperCase());
}
export const convertAdUrl = (videoDetails, showDetails, appInfo, is_live) => {
  const adUrl = showDetails?.ad_link || videoDetails?.ad_link;
  const currentLocation = appInfo?.projectDetails?.geoInfo;
  let uId = localStorage.getItem("gid");
  let user_id = localStorage.getItem("userId");
  if (user_id) {
    uId = user_id;
  }
  const width = window.innerWidth;
  const height = window.innerHeight;
  const autoplay = "0";
  const mute = "0";
  const os = checkOperatingSystem()
  const osVersion = checkOsVersion()
  const deviceBrandName = "";
  const isp = currentLocation?.isp;
  const videoTitle = videoDetails?.video_title ? encodeURIComponent(videoDetails?.video_title) : ""
  const videoUrl = encodeURIComponent(window.location.href)
  const channelName = appInfo?.projectDetails?.projectConfig?.config?.TAB_TITLE
  const season = videoDetails?.season ? videoDetails?.season : ""
  const episode = videoDetails?.video_order ? videoDetails?.video_order : ""
  const series = showDetails?.show_name ? encodeURIComponent(showDetails?.show_name) : "";
  const producer = showDetails?.producer ? showDetails?.producer : "";
  const isLive = is_live ? "1" : "0"
  const rating = showDetails?.rating ? showDetails?.rating : "";
  const language = showDetails?.audio_language_name ? showDetails?.audio_language_name : "";
  const adPosition = "7"
  const placement = "1"
  const skippable = "0"
  const productionQuality = "1"
  const consent = "0"
  const GDPR = isCountryInEU(currentLocation?.countryCode) === true ? "1" : "0"
  const coppa = "1"
  const domain = encodeURIComponent(window.location.origin)
  const url = encodeURIComponent(window.location.href)
  const encodedURL = encodeURIComponent(window.location.href)
  const lmt = ""
  const cacheBuster = new Date().getTime();
  const timestamp = Math.floor(Date.now() / 1000);
  const timestampMS = Date.now();
  const dnt = "0";
  const ipAddress = currentLocation?.query;
  const latitude = currentLocation?.lat;
  const longitude = currentLocation?.lon;
  const userAgent = encodeURIComponent(navigator.userAgent);
  const deviceIfa = "";
  const uuid = "";
  const country = currentLocation?.countryCode;
  const deviceId = appInfo?.projectDetails?.device_id;
  const keyword = showDetails?.video_tags?.length > 0 ? showDetails?.video_tags
    .map((item) => item)
    .join() :
    showDetails?.categories
      .map((item) => item.category_name)
      .join();
  const deviceModel = encodeURIComponent(navigator.userAgent);
  const deviceMake = encodeURIComponent(navigator.userAgent);
  const deviceType = "web";
  const channelId = appInfo?.projectDetails?.projectConfig?.channelid;
  const userId = uId;
  const videoId = videoDetails.video_id;
  const bundleId = "";
  const appName = "";
  const duration = videoDetails?.video_duration
    ? videoDetails?.video_duration * 60
    : "";
  const appstoreUrl = "";
  const city = currentLocation?.city;
  const region = currentLocation?.region;
  const showid = showDetails?.show_id;
  const categories = showDetails?.categories
    .map((item) => item.category_name)
    .join();
  const description = encodeURIComponent(videoDetails?.video_description)
  const appId = appInfo?.projectDetails?.projectConfig?.app_id
  const usPrivacy = ""
  const IABcategory = ""
  const minimumDuration = ""
  const maximumDuration = ""
  const finalAdurl = adUrl
    .replace("[WIDTH]", width)
    .replace("[HEIGHT]", height)
    .replace("[DNT]", dnt)
    .replace("[AUTOPLAY]", autoplay)
    .replace("[MUTE]", mute)
    .replace("[OS]", os)
    .replace("[OS_VERSION]", osVersion)
    .replace("[ISP]", isp)
    .replace("[DEVICE_BRAND_NAME]", deviceBrandName)
    .replace("[VIDEO_TITLE]", videoTitle)
    .replace("[VIDEO_TITLE]", videoTitle)
    .replace("[VIDEO_URL]", videoUrl)
    .replace("[CHANNEL_NAME]", channelName)
    .replace("[SEASON]", season)
    .replace("[EPISODE]", episode)
    .replace("[SERIES]", series)
    .replace("[PRODUCER]", producer)
    .replace("[IS_LIVE]", isLive)
    .replace("[RATING]", rating)
    .replace("[LANGUAGE]", language)
    .replace("[AD_POSITION]", adPosition)
    .replace("[PLACEMENT]", placement)
    .replace("[SKIPPABLE]", skippable)
    .replace("[PRODUCTION_QUALITY]", productionQuality)
    .replace("[CONSENT]", consent)
    .replace("[GDPR]", GDPR)
    .replace("[COPPA]", coppa)
    .replace("[DOMAIN]", domain)
    .replace("[URL]", url)
    .replace("[ENCODED_URL]", encodedURL)
    .replace("[LMT]", lmt)
    .replace("[CACHEBUSTER]", cacheBuster)
    .replace("[TIMESTAMP]", timestamp)
    .replace("[TIMESTAMP_MS]", timestampMS)
    .replace("[IP_ADDRESS]", ipAddress)
    .replace("[LATITUDE]", latitude)
    .replace("[LONGITUDE]", longitude)
    .replace("[USER_AGENT]", userAgent)
    .replace("[DEVICE_IFA]", deviceIfa)
    .replace("[UUID]", uuid)
    .replace("[COUNTRY]", country)
    .replace("[DEVICE_ID]", deviceId)
    .replace("[KEYWORDS]", encodeURIComponent(keyword))
    .replace("[DEVICE_MODEL]", deviceModel)
    .replace("[DEVICE_MAKE]", deviceMake)
    .replace("[CHANNEL_ID]", channelId)
    .replace("[USER_ID]", userId)
    .replace("[VIDEO_ID]", videoId)
    .replace("[BUNDLE]", bundleId)
    .replace("[APP_NAME]", appName)
    .replace("[DURATION]", duration)
    .replace("[APP_STORE_URL]", appstoreUrl)
    .replace("[CITY]", city)
    .replace("[REGION]", region)
    .replace("[SHOW_ID]", showid)
    .replace("[CATEGORIES]", encodeURIComponent(categories))
    .replace("[DESCRIPTION]", description)
    .replace("[DEVICE_TYPE]", deviceType)
    .replace("[APPID]", appId)
    .replace("[US_PRIVACY]", usPrivacy)
    .replace("[IAB_CATEGORY]", IABcategory)
    .replace("[MINIMUM_DURATION]", minimumDuration)
    .replace("[MAXIMUM_DURATION]", maximumDuration)

  return finalAdurl;
};

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

export const getBrowserType = () => {
  var isFirefox = typeof InstallTrigger !== "undefined";
  if (isFirefox === true) {
    return "Firefox";
  }
  var isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
      (typeof window["safari"] !== "undefined" &&
        window["safari"].pushNotification)
    );
  if (isSafari === true) {
    return "Safari";
  }
  var isIE = false || !!document.documentMode;
  if (isIE === true) {
    return "IE";
  }
  var isEdge = !isIE && !!window.StyleMedia;
  if (isEdge === true) {
    return "Edge";
  }
  var isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  if (isChrome === true) {
    return "Chrome";
  }
  var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") !== -1;
  if (isEdgeChromium === true) {
    return "EdgeChromium";
  }
};

export const getFilteredProjectConfig = (key, data) => {
  let filteredItem = data?.filter((item) => {
    return item?.key === key
  })
  return filteredItem[0]?.value
}

export const convertTimeToLocal = (date) => {
  let tempDate = moment.utc(date);
  let localDate = moment(tempDate).local();
  let timeData = localDate.format("hh:mm A");
  return timeData;
};


export const convertTo12HourFormat = (time) => {
  const [hours, minutes] = time.split(':').map(Number);

  // Convert hours to 12-hour format
  let formattedHours = hours % 12;
  if (formattedHours === 0) {
    formattedHours = 12;
  }
  // Determine AM or PM
  const period = hours >= 60 ? 'pm' : hours >= 48 ? 'am' : hours >= 36 ? 'pm' : hours >= 12 ? 'am' : 'pm';

  // Construct the formatted time
  const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;

  return formattedTime;
}

export const getCurrentTime = () => {
  const moment = require('moment');
  let now = moment();
  let currentTime = now.format('hh:mm A');
  return currentTime
}

export const getCurrentTimeAndDate = () => {
  let currentTime = moment().format('YYYY-MM-DDTHH:mm:ss');
  return currentTime
}

export const timeComparisonIsAfter = (endTime) => {
  const formatEndTime = moment(endTime)
  const currentTime = moment();
  if (formatEndTime.isAfter(currentTime)) {
    return true
  } else {
    return false
  }

}
export const timeConversion = (minutes) => {
  var duration = moment.duration(minutes, 'minutes');
  var hours = Math.floor(duration.asHours());
  var remainingMinutes = duration.minutes();
  let format = hours + ' hours ' + remainingMinutes + ' minutes'
  return format
}

export const timeFormatter = (timestamp) => {
  const formattedTime = moment(timestamp).format('hh:mm A');
  return formattedTime
}

export const convertDateAndTimeToLocal = (date) => {
  const localDateTime = moment.utc(date).local();
  const formattedDateTime = localDateTime.format('DD MMM YYYY hh:mm A');
  return formattedDateTime
}


export const fetchApiData = async (appInfo, api, params, userRegisterId) => {
  let uid = localStorage.getItem("gid");
  let user_id = localStorage.getItem("userId");
  let countryCode = appInfo?.projectDetails?.geoInfo?.countryCode;
  let ipaddress = appInfo?.projectDetails?.geoInfo?.query;
  let deviceId = appInfo?.projectDetails?.device_id;
  let token = appInfo?.accessToken;
  let pubid = appInfo?.projectDetails?.projectConfig?.pubid;
  let channelid = appInfo?.projectDetails?.projectConfig?.channelid;
  if (user_id) {
    uid = user_id
  }
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: userRegisterId ? userRegisterId : uid,
      pubid: pubid,
      country_code: countryCode,
      channelid: channelid,
      dev_id: deviceId,
      ip: ipaddress,
      device_type: "web",
    },
    params: params && params
  };
  return await axios.get(`${process.env.REACT_APP_API_URL}${api}`, customConfig)

}

export const fetchApiDataV2 = async (appInfo, api, params, userRegisterId) => {
  let uid = localStorage.getItem("gid");
  let user_id = localStorage.getItem("userId");
  let countryCode = appInfo?.projectDetails?.geoInfo?.countryCode;
  let ipaddress = appInfo?.projectDetails?.geoInfo?.query;
  let deviceId = appInfo?.projectDetails?.device_id;
  let token = appInfo?.accessToken;
  let pubid = appInfo?.projectDetails?.projectConfig?.pubid;
  let channelid = appInfo?.projectDetails?.projectConfig?.channelid;
  let deviceType = localStorage.getItem("deviceType")
  if (user_id) {
    uid = user_id;
  }
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: userRegisterId ? userRegisterId : uid,
      pubid: pubid,
      country_code: countryCode,
      channelid: channelid,
      dev_id: deviceId,
      ip: ipaddress,
      device_type: deviceType ? deviceType : "web",
    },
    params: params && params,
  };
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL_V2}${api}`,
      customConfig
    );
    return response

  } catch (err) {
    // if(err?.response?.status === 404){
    //   window.location.href = "/404?nf=true"
    // }

  }

};

export const ideabizUpdateApi = async (appInfo, api, body) => {
  let uid = localStorage.getItem("gid");
  let user_id = localStorage.getItem("userId");
  if (user_id) {
    uid = user_id
  }
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'access-token': appInfo.accessToken,
      uid: uid,
      pubid: appInfo?.projectDetails?.projectConfig?.pubid,
      channelid: appInfo?.projectDetails?.projectConfig?.channelid
    }
  }

  return await axios.post(`${process.env.REACT_APP_API_URL}${api}`, body, config)

}

export const updateApiData = async (appInfo, api, location) => {

  let uid = localStorage.getItem("gid");
  let user_id = localStorage.getItem("userId");
  let countryCode = appInfo?.projectDetails?.geoInfo?.countryCode;
  let ipaddress = appInfo?.projectDetails?.geoInfo?.query;
  let deviceId = appInfo?.projectDetails?.device_id;
  let token = appInfo?.accessToken;
  let pubid = appInfo?.projectDetails?.projectConfig?.pubid;
  let channelid = appInfo?.projectDetails?.projectConfig?.channelid;
  if (user_id) {
    uid = user_id
  }
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: uid,
      pubid: pubid,
      country_code: countryCode,
      channelid: channelid,
      dev_id: deviceId,
      ip: ipaddress,
      device_type: "web",
    },
  };
  const data = location?.values
  if (location?.path === "accountUpdate") {
    const formData = new FormData();
    if (location?.values?.newPassword) {
      formData.append("password", location?.values?.cPassword);
      formData.append("new_password", location?.values?.newPassword);
    } else {
      formData.append("password", location?.values?.currentPassword);
      formData.append("file", location?.values?.file);
      formData.append("country_code", location?.values?.country);
      formData.append("first_name", location?.values?.firstName);
      formData.append("last_name", location?.values?.lastName);
      formData.append("phone", location?.values?.phone);
    } return await axios.post(`${process.env.REACT_APP_API_URL}${api}`, formData, customConfig,)
  } else {
    return await axios
      .post(
        `${process.env.REACT_APP_API_URL}${api}`,
        qs.stringify(data),
        customConfig
      )
  }




}
export const updateApiDataV2 = async (appInfo, api, location) => {
  let uid = localStorage.getItem("gid");
  let user_id = localStorage.getItem("userId");
  let countryCode = appInfo?.projectDetails?.geoInfo?.countryCode;
  let ipaddress = appInfo?.projectDetails?.geoInfo?.query;
  let deviceId = appInfo?.projectDetails?.device_id;
  let token = appInfo?.accessToken;
  let pubid = appInfo?.projectDetails?.projectConfig?.pubid;
  let channelid = appInfo?.projectDetails?.projectConfig?.channelid;
  let deviceType = localStorage.getItem("deviceType")
  if (user_id) {
    uid = user_id;
  }
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: uid,
      pubid: pubid,
      country_code: countryCode,
      channelid: channelid,
      dev_id: deviceId,
      ip: ipaddress,
      device_type: deviceType ? deviceType : "web",
    },
  };
  const data = location?.values;
  if (location?.path === "accountUpdate") {
    const formData = new FormData();
    if (location?.values?.newPassword) {
      formData.append("password", location?.values?.currentPassword);
      formData.append("new_password", location?.values?.newPassword);
    } else {
      formData.append("password", location?.values?.currentPassword);
      formData.append("file", location?.values?.file);
      formData.append("country_code", location?.values?.country);
      formData.append("first_name", location?.values?.firstName);
      formData.append("last_name", location?.values?.lastName);
      formData.append("phone", location?.values?.phone);
    }
    return await axios.post(
      `${process.env.REACT_APP_API_URL}${api}`,
      formData,
      customConfig
    );
  } else {
    return await axios.post(
      `${process.env.REACT_APP_API_URL}${api}`,
      qs.stringify(data),
      customConfig
    );
  }
};

export const onVideoPlayFunction = async (appInfo, videoDetails, event, currentTime, isLive, categories, showId) => {
  let sessionId = appInfo?.sessionId
  let uId = localStorage.getItem("gid");
  let videoTime =
    currentTime == 0 || currentTime == undefined ? "" : currentTime.toString();
  let user_id = localStorage.getItem("userId");
  if (user_id) {
    uId = user_id;
  }
  const deviceId = appInfo?.projectDetails?.device_id;
  let ctimestamp = Date.now().toString();
  let ctime = ctimestamp.slice(0, 10);
  const appid = appInfo?.projectDetails?.projectConfig?.app_id;
  let token = appInfo?.accessToken;
  const channelId = appInfo?.projectDetails?.projectConfig?.channelid;
  const pubId = appInfo?.projectDetails?.projectConfig?.pubid;
  const data = {
    session_id: sessionId,
    user_id: uId,
    device_id: deviceId,
    publisherid: pubId,
    app_id: appid,
    channel_id: channelId,
    event_type: event,
    video_id: videoDetails?.video_id ? videoDetails?.video_id : "0",
    show_id: showId,
    is_live: isLive,
    video_title: videoDetails?.video_title ? videoDetails?.video_title : videoDetails?.event_name,
    timestamp: ctime,
    video_time: videoTime,
    category: categories,
    event_id: videoDetails?.event_id


  };
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
    },
  };

  return await axios
    .post(
      "https://analytics.poppo.tv/event",
      qs.stringify(data),
      customConfig
    )
}

export const affliateAnalytics = async (appInfo) => {
  let deviceType;
  if (checkOperatingSystem() === "android") {
    deviceType = "Android"
  } else if (checkOperatingSystem() === "iPhone") {
    deviceType = "iPhone"
  }
  let pubid = appInfo?.projectDetails?.projectConfig?.pubid;
  let channelid = appInfo?.projectDetails?.projectConfig?.channelid;
  const url = localStorage.getItem("affliateUrl")
  const unique = deviceType && appInfo?.projectDetails?.geoInfo?.query + deviceType;
  let params = { url: url, source_application: document.referrer, unique_device_id: unique }
  let uid = localStorage.getItem("gid");
  let user_id = localStorage.getItem("userId");
  if (user_id) {
    uid = user_id;
  }
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      pubid: pubid,
      channelid: channelid,
      uid: uid,
      device_type: "web"
    },
    params: params
  };

  return await axios.get(
    "https://api.gizmott.com/api/v1/affiliate/analytics",
    customConfig
  );
};

export const getEventInfo = async (appInfo, eventId) => {
  const api = `event/${eventId}`
  return await fetchApiData(appInfo, api)
}

export const getRandomItem = (arr) => {

  // get random index value
  const randomIndex = Math.floor(Math.random() * arr.length);
  // get random item
  const item = arr[randomIndex];

  return item;
}

export const secondsToMinutes = (seconds) => {
  // calculate minutes by dividing seconds by 60
  let minutes = Math.floor(seconds / 60);
  // return formatted string with minutes only
  return minutes < 10 ? minutes.toString() : minutes.toString().padStart(2, "0");
}

const stringify = (str) => {
  if (str != undefined && str != null) {
    return String(str)
  } else {
    return ""
  }
}

export const ssaiAdParam = async (videoDetails, appInfo) => {
  const currentLocation = appInfo?.projectDetails?.geoInfo;
  // let uId = 74961;
  // let user_id = service.getCookie('userId');
  // if (user_id) {
  //     uId = user_id;
  // }

  var adsParams = {
    "adsParams": {
      "advid": "",
      "appname": stringify(videoDetails.channel_name),
      "bundleid": "",
      "channelid": stringify(videoDetails.channel_id),
      "country": stringify(currentLocation.country_name),
      "description_url": "testing ssai",
      "device_make": stringify(navigator.userAgent),
      "device_model": stringify(navigator.userAgent),
      "device_type": stringify(navigator.userAgent),
      "deviceid": "TestingSSAI",
      // "deviceid": stringify(appInfo?.projectDetails?.device_id),
      "dnt": "true",
      "height": stringify(window.innerHeight),
      "ip": stringify(currentLocation.IPv4),
      "kwds": "JustWatchMe",
      "lat": stringify(currentLocation.latitude),
      "lon": stringify(currentLocation.longitude),
      "totalduration": '',
      "ua": stringify(navigator.userAgent),
      "userid": localStorage.getItem('userId') ? stringify(localStorage.getItem('userId')) : stringify(localStorage.getItem('gid')),
      "uuid": "",
      "videoid": stringify(videoDetails.video_id),
      "width": stringify(window.innerWidth)
    }
  }

  return adsParams;

}
export const getCurrentUTCTimestamp = () => {
  const currentUtcTimestamp = moment().utc().unix();;
  return currentUtcTimestamp
}

export const timeDifferenceWithCurrenttime = (time) => {
  const currentTimeUnix = Math.floor(Date.now() / 1000);
  const timeDifference = currentTimeUnix - time;
  if (timeDifference <= 0) {
    return "Just now";
  } else if (timeDifference < 60) {
    return `${timeDifference} second${timeDifference === 1 ? '' : 's'} ago`;
  } else if (timeDifference < 3600) {
    const minutes = Math.floor(timeDifference / 60);
    return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
  } else if (timeDifference < 86400) {
    const hours = Math.floor(timeDifference / 3600);
    return `${hours} hour${hours === 1 ? '' : 's'} ago`;
  } else if (timeDifference < 604800) {
    const days = Math.floor(timeDifference / 86400);
    return `${days} day${days === 1 ? '' : 's'} ago`;
  } else if (timeDifference < 2419200) {
    const weeks = Math.floor(timeDifference / 604800);
    return `${weeks} week${weeks === 1 ? '' : 's'} ago`;
  } else if (timeDifference < 29030400) {
    const months = Math.floor(timeDifference / 2419200);
    return `${months} month${months === 1 ? '' : 's'} ago`;
  } else {
    const years = Math.floor(timeDifference / 29030400);
    return `${years} year${years === 1 ? '' : 's'} ago`;
  }
};
export const handleChangeColor = (color) => {
  if (color?.BACKGROUND_COLOR) {
    document.documentElement.style.setProperty(
      "--bg-color",
      color?.BACKGROUND_COLOR
    );
  }

  if (color?.BUTTON_COLOR) {
    document.documentElement.style.setProperty(
      "--button-color",
      color?.BUTTON_COLOR
    );
    document.documentElement.style.setProperty(
      "--secondary-color",
      color?.BUTTON_COLOR
    );
  }

  if (color?.BUTTON_SELECTION_COLOR) {
    document.documentElement.style.setProperty(
      "--button-hover",
      color?.BUTTON_SELECTION_COLOR
    );
    document.documentElement.style.setProperty(
      "--text-hover",
      color?.BUTTON_SELECTION_COLOR
    );
  }

};
