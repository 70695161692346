import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { convertAdUrl } from '../../utils/utils'
import Translate from "../MultiLanguage/Translate";
import { useSelector } from "react-redux";

const WatchWithoutAdsModal = (props) => {

  const navigate = useNavigate()
  const user = useSelector((state) => state?.user?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const location = useLocation();
  const showId = location?.state?.showDetails?.showId;
  const watchWithoutAdsHandler = async (value) => {

    if (value === "yes") {
      if (!user) { navigate("/login", { state: { ...location?.state, path: location?.pathname, showId: showId } }); }
      else {
        navigate("/subscription", { state: { videoId: props?.videoDetails?.video_id } })
      }
    }
    else if (value === "no") {
      if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true") {
        if (!user) {
          navigate("/login", { state: { ...location?.state, path: location?.pathname, showId: showId } });
        }
        else if (projectInfo?.projectConfig?.config?.AD_REQUIRED === "true") {
          {
          
            const adUrlWithMacros = convertAdUrl(props?.videoDetails,props?.showDetails, props?.appInfo);
            props?.showDetails?.ad_link || props?.videoDetails?.ad_link &&
              props?.setAdUrl(adUrlWithMacros);
            props?.playVideo()
            props?.setWatchWithoutAds(false)
          }

        }
        else {
          props?.playVideo()
          props?.setWatchWithoutAds(false)
        }
      }else if (projectInfo?.projectConfig?.config?.AD_REQUIRED === "true") {
        {
        
          const adUrlWithMacros = convertAdUrl(props?.videoDetails,props?.showDetails, props?.appInfo);
          props?.showDetails?.ad_link || props?.videoDetails?.ad_link &&
            props?.setAdUrl(adUrlWithMacros);
          props?.playVideo()
          props?.setWatchWithoutAds(false)
        }

      }else {
        props?.playVideo()
        props?.setWatchWithoutAds(false)
      }
    }
  }
  return (
    <div className="watchWithoutAds">
      <div className="overlayModal"></div>
      <div className="contents">
        <h1><Translate textKey={'watch_without_ads'} /></h1>
        <div className="buttons">
          <div className="buttonContainer" onClick={() => watchWithoutAdsHandler("yes")}>
            <div className="background"></div>
            <button ><Translate textKey={'yes'} /></button>
          </div>

          <div className="buttonContainer" onClick={() => watchWithoutAdsHandler("no")}>
            <div className="background"></div>
            <button ><Translate textKey={'no'} /></button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default WatchWithoutAdsModal
