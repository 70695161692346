import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import * as service from "./service";
import { useSelector } from "react-redux";
import {toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const HeaderCategories = () => {
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const [menuItems, setMenuItems] = useState();
  const navigate = useNavigate()
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };  
    useEffect(() => {
        fetchMenuItems();
      }, [user]);
    const fetchMenuItems = async () => {
        try {
          const menuResponse = await service.getMenuItems(appInfo);
          if (menuResponse?.status === 200) {
            setMenuItems(menuResponse?.data?.data);
          }
        } catch (err) {
          toast.error(err?.response?.data?.message, {
            position: "top-center",
          });
        }
      };

      const categoryClickHandler = (item) => {
        navigate(`/category/${item?.key}`,{state:{careers:item?.key}})
    }
  return (
    <div className="headerCategories">
    <Swiper
      modules={[Navigation]}
      spaceBetween={0}
      slidesPerView={7}
      navigation={true}
      watchSlidesProgress
      loop={false}
      breakpoints={{
        320:{
            slidesPerView:3,
            spaceBetween:10
        },
        360:{
            slidesPerView:4,
            spaceBetween:10
        },
        980:{
            slidesPerView:7,
            spaceBetween:10
        }
      }}
    >
        {
            menuItems?.map((mainItem,index)=>{
            
                return mainItem?.items?.map((secondaryItem,index)=>(
                <SwiperSlide>
                 <button onClick={()=>categoryClickHandler(secondaryItem)}>{secondaryItem?.value}</button>
                </SwiperSlide>
                 
                ))
            })
        }

    </Swiper>

    </div>
  );
};

export default HeaderCategories;
