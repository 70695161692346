import React, { useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { imageUrlCheck } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchWatchlistShows } from "../../Screens/MyList/service";
import { updateWatchlistData } from "../../network/service";
import { getMyListData } from "../../Redux/MyList/MyListSlice";

const Card2 = ({ data }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const user = useSelector((state) => state?.user?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  
  const thumbnailOrientation =
    projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;
  const [watchlistStatus, setWatchlistStatus] = useState();
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const appInfo = {
        projectDetails: projectInfo,
        accessToken: accessToken,
      };
    const addToWatchlist = (e,showId) => {
      e.stopPropagation();
        if (user) {
          updateWatchlist(showId, 1);
        } else {
          navigate("/login");
        }
      };
    
      const removeFromWatchlist = (e,showId) => {
        e.stopPropagation();
        updateWatchlist(showId, 0);
      };

      const fetchWatchlist = async () => {
        const response = await fetchWatchlistShows(appInfo);
        if (response?.status === 200) {
          dispatch(
            getMyListData({
              myList: response?.data?.data,
            })
          );
        }
      };

      const updateWatchlist = async (showId, flag) => {
        try {
          const response = await updateWatchlistData(appInfo, showId, flag);
    
          if (response?.status === 200) {
            fetchWatchlist();
            if (flag === 1) {
              // toast.success("Added to mylist", {
              //   position: "bottom-center",
              // });
              setWatchlistStatus("added");
            } else {
              // toast.success("Removed from mylist", {
              //   position: "bottom-center",
              // });
              setWatchlistStatus("removed");
            }
          }
        } catch (err) {
          console.log(err);
        }
      };

      const showHandleClick = () => {
        navigate(`/show-details/${data?.vanity_url}`, {
          state: { showId: data?.show_id, type: data?.type },
        });
      }
  return (
    <div className="relet_video_container" onClick={showHandleClick}>
      <div className="video_image_container">
        {imageUrlCheck(
          thumbnailOrientation === "PORTRAIT"
            ? data?.logo || data?.thumbnail
            : data?.logo_thumb || data?.thumbnail_350_200
        ) === false ? (
          <img
            src={
              thumbnailOrientation === "PORTRAIT"
                ? `${process.env.REACT_APP_IMAGE_URL}${
                    data?.logo || data?.thumbnail
                  }`
                : `${process.env.REACT_APP_IMAGE_URL}${
                    data?.logo_thumb || data?.thumbnail_350_200
                  }`
            }
            alt="ShowImage"
          />
        ) : (
          <img
            src={
              thumbnailOrientation === "PORTRAIT"
                ? `${data?.logo || data?.thumbnail}`
                : `${data?.logo_thumb || data?.thumbnail_350_200}`
            }
            alt="ShowImage"
          />
        )}
      </div>
      <div className="videoCard__info">
        <div className="videoCard__text">
          <h4>{data?.show_name}</h4>
          <div className="add_remove_icon">
          {data?.show_id&& (
            <>
              {watchlistStatus === "added" ? (
                <div
                  className="addToList"
                  onClick={(e) => removeFromWatchlist(e,data?.show_id)}
                >
                  Remove from My List <CancelIcon />
                </div>
              ) : (
                <div
                  className="addToList"
                  onClick={(e) => addToWatchlist(e,data?.show_id)}
                >
                 Add to My List <CheckCircleIcon sx={{width:"16px",marginLeft:"6px"}}/>
                </div>
              )}
            </>
          )}
            {/* {isIconClick === false && <CancelIcon />} */}
            {/* {isIconClick === true && <CheckCircleIcon />} */}
          </div>
          <p>
            {data?.duration_text} • {data?.year}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card2;
