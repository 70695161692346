import { createSlice } from "@reduxjs/toolkit";

export const MenuBarSlice=createSlice({
    name:"menuBar",
    initialState:{
        value:"true",
        
    },
    reducers:{
        getMenuBarData:(state,action)=>{
           state.value=action?.payload?.menuBar
        }
        
    }

})
export const {getMenuBarData}=MenuBarSlice.actions
export default MenuBarSlice.reducer
